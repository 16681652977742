import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import NotesAPI from "../../../api/NotesAPI";

export default function UserNoteTool(props) {
    const {id} = props;
    const [updT, setUpdT] = useState(0);
    const [message, setMessage] = useState('');
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        NotesAPI.getNote(id).then(s => {
            setMessage(s);
            setText(s);
            setUpdT(+new Date());
        });
    }, [id]);

    return (
        <Wrapper>

            <TextareaPlaceholder key={updT}>
                <Textarea value={text} onChange={evt => {
                    setText(evt.target.value);
                }}/>
            </TextareaPlaceholder>

            <ButtonPlaceholder>
                {loading == true ? 'loading...' :
                    <Button onClick={async () => {
                        setLoading(true);
                        await NotesAPI.saveNote(id, text);
                        setLoading(false);
                    }}>
                        Save
                    </Button>
                }
            </ButtonPlaceholder>

        </Wrapper>
    );
}

const ButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Button = styled.div`
    height: 32px;
    border-radius: 4px;
    background: blue;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
`;


const Wrapper = styled.div`
    
`;

const TextareaPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const Textarea = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    min-height: 6em;
`;

