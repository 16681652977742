import DashboardPanel from "./components/dashboard/DashboardPanel";
import SimpleApp from "./components/apps/SimpleApp";


function App() {
    return (
        <SimpleApp/>
    );
}

export default App;
