import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../../api/RonasAPI";

export default function AddUserTool(props) {
    const {
        tag = undefined,
        onSave = u => {

        }
    } = props;

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(undefined);
    const [text, setText] = useState('');

    return (
        <Wrapper>

            <InputPlaceholder>
                <Input placeholder={'email or code'} value={text} onChange={evt => {
                    setText(evt.target.value.replace(/ /g, ''));
                    setUser(undefined);
                }}/>
            </InputPlaceholder>

            <AddButton onClick={async () => {
                setLoading(true);
                setUser(undefined);
                let u = await RonasAPI.getUserByEmail(text);
                if (u == undefined) {
                    window.alert('User is not found');
                }
                setUser(u);
                setLoading(false);
            }}>
                <Spin visible={loading}/>
                {loading == true ? null :
                    'Find user'
                }

            </AddButton>

            {user == undefined ? null :
                <div style={{marginTop: 10}}>
                    <div>
                        <div>
                            {user.uuid}
                        </div>
                        <div>
                            {user.first_name}
                        </div>
                        <div>
                            {user.last_name}
                        </div>
                        <div>
                            {user.email}
                        </div>
                    </div>
                    <div style={{marginTop: 10}}>
                        <AddButton onClick={() => {
                            let isCodeMode = (user.code != undefined && user.email == undefined);
                            let dU = {
                                id: user.uuid,
                                name: isCodeMode ? user.code : `${user.first_name} ${user.last_name}`,
                                email: isCodeMode ? user.code : user.email,
                                tags: (tag == undefined) ? [] : [tag]
                            }
                            onSave(dU);
                        }}>
                            Add
                        </AddButton>
                    </div>

                </div>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const InputPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const AddButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    background: blue;
    :hover{
      opacity: 0.8;
    }
`;


const Input = styled.input`
    height: 40px;
    box-sizing: border-box;
    padding-left: 5px;
    border: none;
    border-bottom: 1px solid grey;
    outline: none;
    width: 100%;
`;
