import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../../api/RonasAPI";

export default function UpdateUserTagsTool(props) {
    const {
        id, onUpdated = () => {

        },
        isSuperAdmin = false
    } = props;
    const [allTags, setAllTags] = useState(props.allTags);
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        setUser(undefined);
        RonasAPI.getAllTags().then(arr => {
            // setAllTags(arr);
            RonasAPI.getUserById(id).then(u => {
                setUser(u);
            });
        })
    }, [id]);

    if (user == undefined) {
        return (
            <div>
                loading...
            </div>
        )
    }

    let userTags = (user.tags == undefined) ? [] : user.tags;

    return (
        <Wrapper>

            <TagsList>
                {allTags.map((tg, i) => {
                    let isSelected = (userTags.indexOf(tg) > -1);
                    return (
                        <TagItem key={tg} selected={isSelected} onClick={async () => {
                            let newTags = (isSelected == true) ? userTags.filter(x => (x != tg)) : userTags.concat([tg]);
                            if (isSuperAdmin == false && newTags.length == 0){
                                return;
                            }
                            let uu = {
                                ...user,
                                tags: newTags
                            }
                            await RonasAPI.saveUser(uu);
                            let user_ = await RonasAPI.getUserById(id);
                            setUser(user_);
                            onUpdated();
                        }}>
                            {tg}
                        </TagItem>
                    )
                })}
            </TagsList>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 10px;
`;

const TagsList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TagItem = styled.div`
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid lightgrey;
    margin-right: 20px;
    cursor: pointer;
    background: ${props => (props.selected == true ? '#8bc9a7' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
`;
