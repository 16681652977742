import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import PushNotificationForm from "./PushNotificationForm";
import RonasAPI from "../../../api/RonasAPI";

export default function PushNotificationTool(props) {
    const {
        uuid
    } = props;
    const [loading, setLoading] = useState(false);

    return (
        <Wrapper>
            <PushNotificationForm loading={loading} onSave={async d => {
                setLoading(true);
                await RonasAPI.sendPush(uuid, d.title, d.body);
                setLoading(false);
                window.alert('Notification has been sent');
            }}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;