import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../../api/RonasAPI";

export default function FirebaseTokensViewerTool(props) {
    const {uuid} = props;
    const [tokens, setTokens] = useState([]);

    useEffect(() => {
        RonasAPI.getFirebaseTokens(uuid).then(arr => {
            setTokens(arr);
        });
    }, [uuid]);

    let goodTokens = tokens.filter(x => (x != undefined && x.indexOf(':') > -1));

    return (
        <Wrapper>
            <div style={{fontSize: 14, opacity: 0.5}}>
                {`${goodTokens.length} firebase tokens found for this user: `}
            </div>
            <div style={{opacity: 0.3}}>
                {goodTokens.join(', ')}
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  font-size: 10px;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
`;