import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import HeatCalendar, {perc2color} from "./tools/HeatCalendar";
import RonasAPI from "../../api/RonasAPI";
import UptimeDayDetailedTool from "./tools/UptimeDayDetailedTool";
import AddUserTool from "./tools/AddUserTool";

import Select from 'react-select'

import momentTz from 'moment-timezone'

import atomImg from '../../assets/images/atom.svg'
import chEmpty from '../../assets/images/ch_void_2.svg'
import chFilled from '../../assets/images/checkbox.svg'
import archImage from '../../assets/images/cardboard-box.svg'

import ls from 'local-storage'
import UpdateUserTagsTool from "./tools/UpdateUserTagsTool";
import DayLogsTool from "./tools/DayLogsTool";
import PreventicusCustomerStatusSpan from "./tools/PreventicusCustomerStatusSpan";
import UserNoteTool from "../notes/tools/UserNoteTool";
import AndroidStatsTool from "../android/AndroidStatsTool";
import UpdateAppVersionTool from "./tools/UpdateAppVersionTool";
import NotificationsPanel from "../notifications/panels/NotificationsPanel";
import ResetPwdTool from "../pwd/ResetPwdTool";
import UserTrialsPanel from "../trials/UserTrialsPanel";
import ExportDataTool from "./tools/ExportDataTool";
import TokensSpan from "./tools/TokensSpan";

import fImg from '../../assets/images/foreign.svg';
import Tabs, {TabItem} from "../ui/Tabs";
import DaySummarySmartTool from "../summaries/tools/DaySummarySmartTool";
import {Button} from "arui-feather/button";
import ExportHelper from "../../helpers/ExportHelper";
import ExportPPGSlotDataTool from "./tools/ExportPPGSlotDataTool";
import AllDoctorsPanel from "../doctors/panels/AllDoctorsPanel";
import FirebaseTokensViewerTool from "./tools/FirebaseTokensViewerTool";

export const ENDPOINT = `https://api.aoc.cardiomood.com`;

export default function DashboardPanel(props) {

    const [addModalVisible, setAddModalVisible] = useState(false);
    const [allTags, setAllTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState(undefined);

    const [selectedActivationStatus, setSelectedActivationStatus] = useState(undefined);

    const [showArchived, setShowArchived] = useState(false);

    const [settingsOpen, setSettingsOpen] = useState(false);

    const [archiving, setArchiving] = useState();

    const [sequencesData, setSequencesData] = useState(undefined);

    const [showEmail, setShowEmail] = useState(false);
    const [showShortStats, setShowShortStats] = useState(true);

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState([]);
    const [selectedMonthTimestamp, setSelectedMonthTimestamp] = useState(+new Date());
    const [dataDays, setDataDays] = useState([]);
    const [minuteItems, setMinuteItems] = useState([]);

    const [selectedDayTimestamp, setSelectedDayTimestamp] = useState();
    const [selectedSlotId, setSelectedSlotId] = useState(undefined);

    const [text, setText] = useState('');

    const [accessModalOpen, setAccessModalOpen] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState(ls('sel-user-id'));
    const [selectedSessionId, setSelectedSessionId] = useState(undefined);
    const [points, setPoints] = useState([]);

    const [filterMode, setFilterMode] = useState('all');

    const [resetModalVisible, setResetModalVisible] = useState(false);

    const [exportVisible, setExportVisible] = useState(false);

    const [userCloudsInfo, setUserCloudsInfo] = useState(undefined);
    const [exporting, setExporting] = useState(false);

    const [monthSummariesData, setMonthSummariesData] = useState(undefined);

    let selectedUser = users.filter(x => (x.id == selectedUserId))[0];

    useEffect(() => {
        if (selectedUserId == undefined || selectedDayTimestamp == undefined) {
            return;
        }
        let startGeneva = +momentTz.tz(moment(selectedDayTimestamp).format('YYYY-MM-DD'), 'Europe/Berlin').startOf('day');
        let endGeneva = +momentTz.tz(moment(selectedDayTimestamp).format('YYYY-MM-DD'), 'Europe/Berlin').endOf('day');

        setSequencesData(undefined);
        RonasAPI.getSequencesForInRange(selectedUserId, +startGeneva - 3600000, +endGeneva + 3600000).then(d => {
            console.log('not filtered sequences data = ', d);
            d = d.filter(x => (+x.start_timestamp >= +startGeneva && +x.start_timestamp <= +endGeneva)).map(x => ({
                ...x,
                startFromMidnight: +x.start_timestamp - +startGeneva
            })).map(x => ({...x, slotNumber: Math.floor(+x.startFromMidnight / 1800000.0)}));
            console.log('sequences data = ', d);
            // let startGeneva = momentTz()
            // start_timestamp
            setSequencesData(d);
        });
    }, [selectedDayTimestamp]);

    useEffect(() => {
        if (selectedUserId == undefined) {
            return;
        }
        setSelectedDayTimestamp(undefined);
        let date = moment(selectedMonthTimestamp).format('YYYY-MM-DD');
        setDataDays([]);
        setLoading(true);
        RonasAPI.getUserMonthDays(selectedUserId, date).then(arr => {
            console.log('--->>> getUserMonthDays: arr = ', arr);
            setDataDays(arr);
            setLoading(false);
        });
        if (selectedUser != undefined) {
            setMonthSummariesData([]);
            RonasAPI.getMonthUserSummaries(selectedUser.email, selectedMonthTimestamp).then(dd1 => {
                console.log('--->>> dd1 = ', dd1);
                setMonthSummariesData(dd1);
            });
        }
    }, [selectedUserId, selectedMonthTimestamp, (selectedUser == undefined ? '' : selectedUser.email)]);

    // useEffect(() => {
    //     if (selectedUserId == undefined || selectedDayTimestamp == undefined) {
    //         return;
    //     }
    //     let date = moment(selectedDayTimestamp).format('YYYY-MM-DD');
    //     setMinuteItems([]);
    //     setLoading(true);
    //     RonasAPI.getUserDayItems(selectedUserId, date).then(arr => {
    //         setMinuteItems(arr);
    //         setLoading(false);
    //     })
    // }, [selectedUserId, selectedMonthTimestamp, selectedDayTimestamp]);

    useEffect(() => {
        if (selectedUserId == undefined || selectedDayTimestamp == undefined) {
            return;
        }
        let date = moment(selectedDayTimestamp).format('YYYY-MM-DD');
        setMinuteItems([]);
        setLoading(true);
        // RonasAPI.getUserDayItems(selectedUserId, date).then(arr => {
        RonasAPI.getUserDayItemsBySlotId(selectedSlotId).then(arr => {
            setMinuteItems(arr);
            setLoading(false);
        }).catch(exc => {
            console.log('caught exc = ', exc);
            setMinuteItems([]);
            setLoading(false);
        });
    }, [selectedUserId, selectedMonthTimestamp, selectedDayTimestamp]);

    useEffect(() => {
        ls('sel-user-id', selectedUserId);
        if (selectedUserId != undefined) {
            RonasAPI.getUserInfoFromBothClouds(selectedUserId).then(x => {
                setUserCloudsInfo(x);
            });
        }
    }, [selectedUserId]);


    useEffect(() => {
        RonasAPI.getAllUsers().then(arr => {
            console.log('All users loaded! arr = ', arr);
            // arr = arr.map(x => ({...x, hasIssues: (Math.random() < 0.000001)})); // todo: reimplement
            setUsers(arr);
            // RonasAPI.getAllTags().then(arr2 => {
            RonasAPI.getMe().then(d => d.tags).then(arr2 => {
                setAllTags(arr2);
                setLoading(false);
            });
        })
    }, []);


    if (users.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    if (window.currentUser == undefined) { // bad practice, todo(Sabir): remove later
        return null;
    }

    let {currentUser} = window;
    let {isSuperAdmin, isStudyManager, canAddNewUser = false} = currentUser;
    let selectedSession = sessions.filter(x => (x.id == selectedSessionId))[0];

    console.log('zz-> selectedActivationStatus = ', selectedActivationStatus);
    console.log('zz-> users = ', users);


    let fUsers = users
        .filter(x => (
            (x.name.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1) ||
            (x.preventicusCustomerId != undefined && `${x.preventicusCustomerId}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1) ||
            (x.email != undefined && `${x.email}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1) ||
            (x.preventicusCustomerUUID != undefined && `${x.preventicusCustomerUUID}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1) ||
            (x.deviceInfo != undefined && `${x.deviceId}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1)
        ))
        .filter(x => {
            let {hasIssues = false, archived = false} = x;
            if (showArchived == false && archived == true) {
                return false;
            }
            if (selectedActivationStatus != undefined && x.preventicusCustomerStatus != undefined && (x.preventicusCustomerStatus != selectedActivationStatus)) {
                return false;
            }
            if (x.preventicusCustomerStatus == undefined && selectedActivationStatus != undefined){
                return false;
            }

            if (filterMode == 'all') {
                return true;
            }
            if (hasIssues == true && filterMode == 'with_issues') {
                return true;
            }
            if (hasIssues == false && filterMode == 'without_issues') {
                return true;
            }
            return false;
        }).filter(x => {
            // if (isSuperAdmin == false) {
            //     return true;
            // }
            let uTags = (x.tags == undefined) ? [] : x.tags;
            if (selectedTag == undefined) {
                return true;
            }
            return (uTags.indexOf(selectedTag) > -1);
        });

    console.log('render: fUsers = ', fUsers);

    const tagsOptions = allTags.map(x => ({value: x, label: x}));

    console.log('render: sequencesData = ', sequencesData);
    console.log('moment(selectedDayTimestamp).format(\'YYYY-MM-DD\') = ', moment(selectedDayTimestamp).format('YYYY-MM-DD'));

    // let startGeneva = +moment(momentTz(+moment(selectedDayTimestamp).startOf('day')).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
    // let endGeneva = +moment(momentTz(+moment(selectedDayTimestamp).endOf('day')).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

    let startGeneva = +momentTz.tz(moment(selectedDayTimestamp).format('YYYY-MM-DD'), 'Europe/Berlin').startOf('day');
    let endGeneva = +momentTz.tz(moment(selectedDayTimestamp).format('YYYY-MM-DD'), 'Europe/Berlin').endOf('day');
    console.log('startGeneva, endGeneva = ', startGeneva, endGeneva);

    let startOfTodayGeneva = +momentTz.tz('Europe/Berlin').startOf('day');
    let maxRecordsPerCurrentMoment = Math.floor((+new Date() - startOfTodayGeneva) / 1800000.0) * 25 * 60 * 30;

    console.log('startGeneva, endGeneva = ', new Date(startGeneva), new Date(endGeneva));

    console.log('render: selectedUser = ', selectedUser);
    let isSelectedUserFranOrCcn = (selectedUser != undefined && selectedUser.tags != undefined && (selectedUser.tags.indexOf('FRAN') > -1 || selectedUser.tags.indexOf('CCN') > -1))

    console.log('render: isStudyManager = ', isStudyManager);
    let monthSummariesMap = (monthSummariesData == undefined ? [] : monthSummariesData).reduce((mp, x) => ({
        ...mp,
        [x.date]: x
    }), {});


    return (
        <Wrapper>

            <Left>

                {window.currentUser == undefined ? null :
                    <CurrentUserPlaceholder>
                        <CurrentUserName>
                            {window.currentUser.name}
                        </CurrentUserName>
                        <span style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontSize: 12,
                            cursor: 'pointer'
                        }}>
                            {isSuperAdmin == false ? null :
                                <span style={{marginRight: 10}} onClick={() => {
                                    setSettingsOpen(true);
                                }}>
                                    settings
                                </span>
                            }
                            {/*{isSuperAdmin == false ? null :*/}
                            {/*    */}
                            {/*}*/}
                            <span style={{marginRight: 10}} onClick={() => {
                                setExportVisible(true);
                            }}>
                                    export
                                </span>
                            <LogoutSpan onClick={() => {
                                ls.remove('mmt-token');
                                window.location.reload();
                            }}>
                                logout
                            </LogoutSpan>
                        </span>
                    </CurrentUserPlaceholder>
                }

                {true == false ? null :
                    <div style={{marginTop: 10, marginBottom: 10, paddingLeft: 5, paddingRight: 5}}>
                        <Select options={tagsOptions}
                                isClearable={true}
                                placeholder={'Select tag'}
                                value={tagsOptions.filter(x => (x.value == selectedTag))[0]}
                                onChange={x => {
                                    if (x == undefined) {
                                        setSelectedTag(undefined);
                                    } else {
                                        setSelectedTag(x.value);
                                    }
                                    setSelectedUserId(undefined);
                                }}/>
                    </div>
                }

                {isSuperAdmin == false ? null :
                    <div style={{marginTop: 10, marginBottom: 10, paddingLeft: 5, paddingRight: 5}}>
                        <Select options={PREVENTICUS_STATUS_OPTIONS}
                                isClearable={true}
                                placeholder={'preventicus activation status'}
                                value={PREVENTICUS_STATUS_OPTIONS.filter(x => (x.value == selectedActivationStatus))[0]}
                                onChange={x => {
                                    if (x == undefined) {
                                        setSelectedActivationStatus(undefined);
                                    } else {
                                        setSelectedActivationStatus(x.value);
                                    }
                                    setSelectedUserId(undefined);
                                }}/>
                    </div>
                }

                <SearchPlaceholder>
                    <Input placeholder={'Search'} autoFocus={true} value={text} onChange={evt => {
                        setText(evt.target.value);
                    }}/>
                </SearchPlaceholder>

                <>
                    {isStudyManager == true ? null :
                        <EmRow onClick={() => {
                            setShowEmail(!showEmail);
                        }}>
                            <ChImage src={(showEmail == true) ? chFilled : chEmpty} style={{marginRight: 7}}/>
                            <span>
                                Show email/code
                            </span>
                        </EmRow>
                    }
                    <EmRow onClick={() => {
                        setShowArchived(!showArchived);
                    }}>
                        <ChImage src={(showArchived == true) ? chFilled : chEmpty} style={{marginRight: 7}}/>
                        <span>
                                Show archived
                            </span>
                    </EmRow>
                    {isStudyManager == true ? null :
                        <EmRow onClick={() => {
                            setShowShortStats(!showShortStats);
                        }}>
                            <ChImage src={(showShortStats == true) ? chFilled : chEmpty} style={{marginRight: 3}}/>
                            <span>
                            Show stats
                        </span>
                        </EmRow>
                    }
                </>

                {/*<FiltersPlaceholder>*/}
                {/*{*/}
                {/*[*/}
                {/*{*/}
                {/*label: 'All',*/}
                {/*value: 'all'*/}
                {/*},*/}
                {/*{*/}
                {/*label: `With issues (${users.filter(x => x.hasIssues).length})`,*/}
                {/*value: 'with_issues'*/}
                {/*},*/}
                {/*{*/}
                {/*label: `Without issues (${users.filter(x => !x.hasIssues).length})`,*/}
                {/*value: 'without_issues'*/}
                {/*},*/}
                {/*].map((a, i) => {*/}

                {/*return (*/}
                {/*<FilterItem*/}
                {/*selected={(a.value == filterMode)}*/}
                {/*onClick={() => {*/}
                {/*setFilterMode(a.value);*/}
                {/*}}>*/}
                {/*{a.label}*/}
                {/*</FilterItem>*/}
                {/*)*/}
                {/*})*/}
                {/*}*/}

                {/*</FiltersPlaceholder>*/}


                {fUsers.map((u, i) => {
                    let {hasIssues = false, statsObject, isRealStudyUser, archived = false} = u;
                    let bgColor = (hasIssues == true ? '#ff7f5085' : 'white');
                    let uTags = (u.tags == undefined) ? [] : u.tags;

                    // let xx = (todaySlot == undefined || todaySlot.measurements_count == undefined) ? 0 : +todaySlot.measurements_count;
                    // let perc = (maxRecordsPerCurrentMoment == 0) ? 100.0 : (100.0 * xx / maxRecordsPerCurrentMoment);

                    let isSelected_ = (selectedUserId == u.id);
                    return (
                        <UserItem key={u.id}
                                  style={{backgroundColor: (selectedUserId == u.id) ? '#8bc9a7' : bgColor}}
                                  selected={isSelected_} onClick={() => {
                            setSelectedUserId(u.id);
                        }}>
                            <div style={{flex: 1}}>
                                <div
                                    style={{display: 'flex', alignItems: 'center', flexDirection: 'row', fontSize: 16}}>
                                    {isRealStudyUser != true ? null :
                                        <CoreImg src={atomImg}/>
                                    }
                                    {archived == false ? null :
                                        <img src={archImage} style={{marginRight: 5, height: 16, opacity: 0.5}}/>
                                    }
                                    {u.name}
                                </div>
                                {showEmail == false ? null :
                                    <div style={{fontSize: 12, opacity: 0.5}}>
                                        {u.email}
                                    </div>
                                }
                                {(statsObject == undefined || showShortStats == false) ? null :
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginTop: 3
                                    }}>
                                        <div style={{
                                            backgroundColor: 'whitesmoke',
                                            padding: 4,
                                            borderRadius: 4,
                                            display: 'inline-block'
                                        }}>
                                            {isSuperAdmin == false ? null :
                                                <StatBadge selected={isSelected_} style={{
                                                    backgroundColor: perc2color(statsObject.month * 100)
                                                }}>
                                                    {`${(statsObject.month * 100.0).toFixed(1)}%`}
                                                </StatBadge>
                                            }
                                            {isSuperAdmin == false ? null :
                                                <StatBadge selected={isSelected_} style={{
                                                    backgroundColor: perc2color(statsObject.week * 100)
                                                }}>
                                                    {`${(statsObject.week * 100.0).toFixed(1)}%`}
                                                </StatBadge>
                                            }
                                            {isSuperAdmin == false ? null :
                                                <StatBadge style={{
                                                    boxSizing: 'border-box',
                                                    backgroundColor: perc2color(statsObject.day * 100),
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    color: 'black'
                                                }}
                                                           selected={isSelected_}>
                                                    {`${(statsObject.day * 100.0).toFixed(1)}%`}
                                                </StatBadge>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            {true == false ? null :
                                <UserItemTagsPlaceholder>
                                    <div>
                                        {uTags.join(', ')}
                                    </div>
                                    {u.deviceInfo == undefined ? null :
                                        <div style={{maxWidth: 120}}>
                                            {`${u.deviceType} - ${u.deviceId}`}
                                        </div>
                                    }
                                </UserItemTagsPlaceholder>
                            }
                        </UserItem>
                    )
                })}

                {canAddNewUser == false ? null :
                    <div style={{padding: 5, display: 'flex', width: '100%', boxSizing: 'border-box'}}>
                        <span style={{
                            cursor: 'pointer',
                            padding: 7,
                            border: '1px solid lightblue',
                            borderRadius: 4,
                            background: '#f0f0f0'
                        }} onClick={() => {
                            setAddModalVisible(true);
                        }}>
                            add user
                        </span>
                    </div>
                }


            </Left>

            <Right>
                {selectedUser == undefined ?
                    <div style={{
                        height: '100vh',
                        padding: 0, display: 'flex', flexDirection: 'column',
                        alignItems: 'center', justifyContent: 'center', textAlign: 'center'
                    }}>
                        <div style={{marginBottom: 10, fontSize: 16, fontWeight: 'bold'}}>
                            Please select user from the list
                        </div>
                        <div style={{opacity: 0.5, fontSize: 12}}>
                            This dashboard uses data from the Prod cloud.
                        </div>

                        <div style={{marginTop: 20, opacity: 0.5, fontSize: 12, marginBottom: 10}}>
                            For visualization of the percentage of data filled in a specific time range these colors are
                            used:
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            {Array.from({length: 21}).map((a, i) => i * 5).map((x, i) => {
                                return (
                                    <div style={{
                                        width: 20,
                                        height: 20,
                                        fontSize: 10,
                                        backgroundColor: perc2color(x),
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {x}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    :
                    <React.Fragment key={selectedUser.id}>
                        <SelectedUserName key={selectedUser.id}>
                        <span>
                            {selectedUser.name}
                        </span>
                            {loading == false ? null :
                                <span style={{marginLeft: 5}}>
                                    <Spin visible={loading}/>
                                </span>
                            }
                            <TokensSpan uuid={selectedUser.id}>
                                <img src={fImg} style={{width: 16, height: 16, marginLeft: 10, opacity: 0.5}}/>
                            </TokensSpan>
                            <AndroidStatsTool id={selectedUser.id}/>

                        </SelectedUserName>
                        <div style={{textAlign: 'center', fontSize: 12, opacity: 0.5, color: 'black'}}>
                            {selectedUser.id}
                        </div>

                        {isSuperAdmin == false ? null :
                            <div>
                                <FirebaseTokensViewerTool uuid={selectedUser.id}/>
                            </div>
                        }

                        {(selectedUser == undefined || selectedUser.deviceInfo == undefined) ? null :
                            <div style={{
                                textAlign: 'center',
                                fontSize: 12,
                                opacity: 0.5,
                                color: 'black',
                                marginTop: 15
                            }}>
                                {`DEVICE ID = ${selectedUser.deviceId} | DEVICE TYPE = ${selectedUser.deviceType}`}
                            </div>
                        }

                        {(userCloudsInfo == undefined || userCloudsInfo.u == undefined) ? null :
                            <div style={{
                                textAlign: 'center',
                                fontSize: 12,
                                opacity: 0.5,
                                color: 'black',
                                marginTop: 15
                            }}>
                                {`customer_tag: ${userCloudsInfo.u.customer_tag == undefined ? 'N/A' : userCloudsInfo.u.customer_tag}`}
                            </div>
                        }

                        <div style={{textAlign: 'center', fontSize: 12, opacity: 0.5, color: 'black'}}>
                            <PreventicusCustomerStatusSpan id={selectedUser.id}/>
                        </div>


                        <HeatCalendar monthTimestamp={selectedMonthTimestamp}
                                      selectedDayTimestamp={selectedDayTimestamp}
                                      days={dataDays}
                                      monthSummariesMap={monthSummariesMap}
                                      onDayClick={(t, slotId) => {
                                          setSelectedDayTimestamp(t);
                                          if (slotId == undefined) {
                                              return;
                                          }
                                          console.log('onDayClick: slotId = ', slotId);
                                          setSelectedSlotId(slotId);
                                      }}
                                      onMonthChange={newT => {
                                          setSelectedMonthTimestamp(newT);
                                      }}/>

                        {true == false ? null :
                            <React.Fragment>
                                <UpdateUserTagsTool id={selectedUser.id}
                                                    isSuperAdmin={isSuperAdmin}
                                                    allTags={allTags}
                                                    onUpdated={async () => {
                                                        let arr = await RonasAPI.getAllUsers();
                                                        setUsers(arr);
                                                    }}/>

                                <div style={{marginTop: 20, marginBottom: 20, paddingLeft: 10}}>
                                    <div style={{
                                        cursor: 'pointer',
                                        marginLeft: 10,
                                        opacity: 0.6,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                         onClick={async () => {
                                             if (archiving == true) {
                                                 return;
                                             }
                                             setArchiving(true);
                                             let isArchived = selectedUser.archived;
                                             if (isArchived) {
                                                 await RonasAPI.unarchiveUser(selectedUser.id);
                                             } else {
                                                 await RonasAPI.archiveUser(selectedUser.id);
                                             }
                                             let arr = await RonasAPI.getAllUsers();
                                             setUsers(arr);
                                             setArchiving(false);
                                         }}>
                                        <img style={{height: 18, marginRight: 15}}
                                             src={selectedUser.archived == true ? chFilled : chEmpty}/>
                                        {archiving == true ? null :
                                            <>
                                                {' in archive'}
                                            </>
                                        }
                                        <Spin visible={archiving}/>

                                    </div>

                                </div>
                            </React.Fragment>
                        }


                        {/*{isSelectedUserFranOrCcn == false && isSuperAdmin == false ? null :*/}
                        {/*    */}
                        {/*}*/}

                        <div style={{padding: 10}}>
                            <h3>
                                Study Sessions
                            </h3>
                            <UserTrialsPanel {...selectedUser} uuid={selectedUser.id} email={selectedUser.email}
                                             isAdmin={isSuperAdmin}
                            />
                        </div>

                        <div style={{padding: 10}}>
                            <h3>
                                Notes
                            </h3>
                            <UserNoteTool id={selectedUser.id}/>
                        </div>

                        {isSelectedUserFranOrCcn == true ? null :
                            <div key={selectedUser.id} style={{padding: 10}}>
                                <h3>
                                    Notifications
                                </h3>
                                <NotificationsPanel id={selectedUser.id}/>
                            </div>
                        }

                        <div style={{marginTop: 120, marginBottom: 20, paddingLeft: 10}}>
                            {isSuperAdmin == false ? null :
                                <span style={{color: 'red', cursor: 'pointer'}} onClick={async () => {
                                    if (window.confirm('Are you sure?') == false) {
                                        return;
                                    }
                                    ls.remove('sel-user-id');
                                    await RonasAPI.deleteUser(selectedUser.id);
                                    let arr = await RonasAPI.getAllUsers();
                                    setSelectedUserId(undefined);
                                    setUsers(arr);
                                }}>
                                    delete user from list
                                </span>
                            }

                            <span style={{color: 'red', cursor: 'pointer', marginLeft: 10, opacity: 0.6}}
                                  onClick={async () => {
                                      setResetModalVisible(true);
                                  }}>
                                    reset user password
                            </span>

                        </div>


                    </React.Fragment>

                }

            </Right>

            <Sidebar width={Math.min(1200, window.innerWidth)}
                     visible={!(selectedDayTimestamp == undefined || selectedUser == undefined)}
                     onCloserClick={() => {
                         setSelectedDayTimestamp(undefined);
                         setSelectedSlotId(undefined);
                     }}>

                {(selectedDayTimestamp == undefined || selectedUser == undefined) ? null :
                    <div>
                        <SelectedUserName>
                            {selectedUser.name}
                            <div style={{fontSize: 12, opacity: 0.5, fontWeight: 'normal'}}>
                                {`${moment(startGeneva).format('DD.MM.YYYY HH:mm')} - ${moment(endGeneva).format('DD.MM.YYYY HH:mm')}`}
                            </div>
                        </SelectedUserName>

                        <Tabs tabs={[
                            {
                                label: 'PPG',
                                content: (
                                    <TabItem>
                                        {selectedSlotId == undefined ?
                                            <div>
                                                No PPG data
                                            </div>
                                            :
                                            <UptimeDayDetailedTool
                                                sequencesData={sequencesData}
                                                selectedSlotId={selectedSlotId}
                                                dayTimestamp={selectedDayTimestamp}
                                                slots={minuteItems} loading={loading}
                                                onDayChange={(tt) => {
                                                    setSelectedDayTimestamp(tt);
                                                }}
                                                onSpotClick={() => {

                                                }}
                                            />
                                        }
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Heart Rate',
                                content: (
                                    <TabItem>
                                        <DaySummarySmartTool uuid={selectedUser.id}
                                                             type={'heart_rate'}
                                                             dayTimestamp={selectedDayTimestamp}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Activity',
                                content: (
                                    <TabItem>
                                        <DaySummarySmartTool uuid={selectedUser.id}
                                                             type={'activity'}
                                                             dayTimestamp={selectedDayTimestamp}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Sleep',
                                content: (
                                    <TabItem>
                                        <div style={{width: '100%', height: 320}}>
                                            <DaySummarySmartTool uuid={selectedUser.id}
                                                                 type={'sleep'}
                                                                 dayTimestamp={selectedDayTimestamp}/>
                                        </div>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Export',
                                content: (
                                    <TabItem>
                                        <Spin visible={exporting}/>
                                        {exporting == true ? null :
                                            <Button onClick={async () => {
                                                setExporting(true);
                                                let pld = await RonasAPI.getRawDataForManyUsersForRange2([selectedUser.email], moment(selectedDayTimestamp).format('YYYY-MM-DD'), moment(selectedDayTimestamp).format('YYYY-MM-DD'));
                                                console.log('xPld = ', pld);
                                                setExporting(false);
                                                await ExportHelper.exportUsersStatsExtended(pld, `${moment(selectedDayTimestamp).format('YYYY-MM-DD')}`);
                                            }}>
                                                Export
                                            </Button>
                                        }
                                        {selectedSlotId == undefined ? null :
                                            <div style={{marginTop: 10}}>
                                                <ExportPPGSlotDataTool slotId={selectedSlotId}
                                                                       user={selectedUser}
                                                />
                                            </div>
                                        }
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Logs',
                                content: (
                                    <TabItem>
                                        {selectedUser == undefined ? 'no logs' :
                                            <div style={{marginTop: 40, width: '100%'}}>
                                                <DayLogsTool userId={selectedUser.id}
                                                             dayTimestamp={selectedDayTimestamp}/>
                                            </div>
                                        }
                                    </TabItem>
                                )
                            }
                        ]}/>


                    </div>
                }

            </Sidebar>

            {/*<Sidebar width={Math.min(1200, window.innerWidth)}*/}
            {/*         visible={(selectedSlotId == undefined && selectedUser != undefined && selectedDayTimestamp != undefined)}*/}
            {/*         onCloserClick={() => {*/}
            {/*             setSelectedDayTimestamp(undefined);*/}
            {/*             setSelectedSlotId(undefined);*/}
            {/*         }}>*/}

            {/*    {!(selectedSlotId == undefined && selectedUser != undefined && selectedDayTimestamp != undefined) ? null :*/}
            {/*        <div>*/}
            {/*            <SelectedUserName>*/}
            {/*                {selectedUser.name}*/}
            {/*                <div style={{fontSize: 12, opacity: 0.5, fontWeight: 'normal'}}>*/}
            {/*                    {`${moment(startGeneva).format('DD.MM.YYYY HH:mm')} - ${moment(endGeneva).format('DD.MM.YYYY HH:mm')}`}*/}
            {/*                </div>*/}
            {/*            </SelectedUserName>*/}

            {/*            {selectedUser == undefined ? null :*/}
            {/*                <div style={{marginTop: 40, width: '100%'}}>*/}
            {/*                    <DayLogsTool userId={selectedUser.id} dayTimestamp={selectedDayTimestamp}/>*/}
            {/*                </div>*/}
            {/*            }*/}


            {/*        </div>*/}
            {/*    }*/}

            {/*</Sidebar>*/}


            <Sidebar
                visible={addModalVisible}
                onCloserClick={() => {
                    setAddModalVisible(false);
                }}>

                {(addModalVisible == false) ? null :
                    <div key={`ddd_${selectedTag}`}>
                        <AddUserTool tag={selectedTag} onSave={async u => {
                            if (u != undefined && u.tags == undefined || u.tags.length == 0) {
                                if (isSuperAdmin == false) {
                                    u.tags = (isSuperAdmin == true) ? [] : currentUser.tags;
                                }
                            }
                            let newUsers = await RonasAPI.saveUser(u);
                            setUsers(newUsers);
                        }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar
                width={620}
                visible={settingsOpen}
                onCloserClick={() => {
                    setSettingsOpen(false);
                }}>

                {(settingsOpen == false) ? null :
                    <div>
                        <div style={{marginBottom: 30}}>
                            <UpdateAppVersionTool os={'android'}/>
                        </div>
                        <div>
                            <UpdateAppVersionTool os={'ios'} label={'iOS app latest version'}/>
                        </div>
                        <div style={{marginTop: 30}}>
                            <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
                                setAccessModalOpen(true);
                            }}>
                                Doctor dashboard access management
                            </span>
                        </div>
                    </div>
                }

            </Sidebar>

            <Sidebar
                width={820}
                visible={accessModalOpen}
                onCloserClick={() => {
                    setAccessModalOpen(false);
                }}>

                {(accessModalOpen == false) ? null :
                    <div>
                        <AllDoctorsPanel/>
                    </div>
                }

            </Sidebar>

            <Sidebar
                width={820}
                visible={resetModalVisible}
                onCloserClick={() => {
                    setResetModalVisible(false);
                }}>

                {(resetModalVisible == false) ? null :
                    <div>
                        <ResetPwdTool code={selectedUser.name}/>
                    </div>
                }

            </Sidebar>

            <Sidebar
                width={Math.min(520, window.innerWidth)}
                visible={exportVisible}
                onCloserClick={() => {
                    setExportVisible(false);
                }}>

                {(exportVisible == false) ? null :
                    <div>
                        <ExportDataTool users={fUsers}/>
                    </div>
                }

            </Sidebar>


        </Wrapper>
    );
}

// {
//     display: 'flex',
//         flexDirection: 'row',
//     alignItems: 'center',
//     marginTop: 5,
//     marginBottom: 5,
//     paddingLeft: 5,
//     cursor: 'pointer'
// }


const CoreImg = styled.img`
  height: 12px;
  width: 12px;
  margin-right: 5px;
`;

const EmRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
  cursor: pointer;
`;

const StatBadge = styled.div`
  font-size: 10px;
  padding-left: 3px;
  padding-right: 3px;
  height: 18px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
  color: black;

  :first-of-type {
    margin-left: 0px;
  }

  :last-of-type {
    margin-right: 0px;
  }
`;

const ChImage = styled.img`
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

const SearchPlaceholder = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px solid lightgrey;
`;

const FiltersPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 5px;
  margin-bottom: 5px;
`;

const FilterItem = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  border: 1px solid lightgrey;
  padding-left: 7px;
  padding-right: 7px;
  background: ${props => (props.selected == true ? 'grey' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: pointer;
  font-size: 12px;
`;

const Input = styled.input`
  outline: none;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 5px;
  border: none;
`;

const SessionsList = styled.div`
  box-sizing: border-box;
  padding: 5px;
`;

const SessionItem = styled.div`
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid lightgrey;

  background: ${props => (props.selected == true ? 'blue !important' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};

  :hover {
    opacity: ${props => (props.selected == true ? 1 : 0.7)};
  }

`;

const SelectedUserName = styled.div`
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid lightgrey;
  font-size: 16px;
  font-weight: bold;
`;

const UserItemTagsPlaceholder = styled.div`
  font-size: 10px;
`;

const UserItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  padding: 5px;
  background: ${props => (props.selected == true ? 'blue' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};

  :hover {
    opacity: ${props => (props.selected == true ? 1 : 0.7)};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
`;

const Left = styled.div`
  width: 320px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  border-right: 1px solid lightgrey;
`;

const CurrentUserPlaceholder = styled.div`
  height: 40px;
  background-image: linear-gradient(243deg, #8bc9a7 0%, #7de0b5 100%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
`;

const CurrentUserName = styled.div`
  font-weight: bold;
`;

const LogoutSpan = styled.div`
  opacity: 0.95;
  font-size: 12px;
  color: white;
  cursor: pointer;

  :hover {
    opacity: 1;
    text-decoration: underline;
  }
`;

const Right = styled.div`
  flex: 1;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
`;

const PREVENTICUS_STATUS_OPTIONS = [
    {
        label: 'pending',
        value: 'pending'
    },
    {
        label: 'expired',
        value: 'expired'
    },
    {
        label: 'confirmed',
        value: 'confirmed'
    },
    {
        label: 'denied',
        value: 'denied'
    },
    {
        label: 'study_has_finished',
        value: 'study_has_finished'
    }
]