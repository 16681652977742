import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../api/RonasAPI";
import LoginApp from "./LoginApp";
import DashboardPanel from "../dashboard/DashboardPanel";

export default function SimpleApp(props) {
    const [user, setUser] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        RonasAPI.getMe().then(u => {
            setUser(u);
            setReady(true);
        })
    }, []);

    if (ready == false) {
        return <Code/>
    }

    console.log('render: user = ', user);

    if (user == undefined) {
        return (
            <LoginApp/>
        )
    }

    return (
        <Wrapper>
            <DashboardPanel currentUser={user} />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
`;
