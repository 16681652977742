import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../../api/RonasAPI";

export default function DayLogsTool(props) {
    const {userId, dayTimestamp} = props;
    let from = +moment(dayTimestamp).startOf('day');
    let to = +moment(dayTimestamp).endOf('day');

    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        setLoading(true);
        RonasAPI.getUserLogsForRange(userId, from, to).then(arr => {
            setLoading(false);
            setLogs(arr);
        });
    }, [from, to, userId]);

    if (logs.length == 0 && loading == true) {
        return (
            <Code/>
        );
    }
    let sortedLogs = logs.sort((a, b) => (+b.timestamp - +a.timestamp));
    if (logs.length == 0) {
        return null;
    }

    return (
        <Wrapper>

            <Heading>
                {`Logs for ${moment(dayTimestamp).format('DD.MM.YYYY')}`}
            </Heading>

            <LogsList>
                {sortedLogs.map((log, i) => {
                    let {awsKey, zipFileName} = log;
                    return (
                        <LogRow key={log.id}>
                            <LogName>
                                {`${moment(log.saveTimestamp).format('DD.MM.YYYY HH:mm:ss')} - ${zipFileName == undefined ? '' : zipFileName}`}
                            </LogName>
                            <LogControl>
                                <DownloadSpan target={'_blank'}
                                              href={`https://oac-log-files.s3.eu-central-1.amazonaws.com/${awsKey}`}>
                                    download
                                </DownloadSpan>
                            </LogControl>
                        </LogRow>
                    )
                })}
            </LogsList>
        </Wrapper>
    );
}

const Heading = styled.div`
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
`;

const DownloadSpan = styled.a`
    cursor: pointer;
    opacity: 0.6;
    color: green;
    :hover{
      opacity: 0.75;
    }
`;

const Wrapper = styled.div`
    
`;

const LogsList = styled.div`
    
`;

const LogRow = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    padding-top: 5px;
    padding-bottom: 5px;
`;

const LogName = styled.div`
    font-size: 14px;
`;

const LogControl = styled.div`
    
`;
