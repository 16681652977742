import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import moonImg from '../../../assets/images/moon.svg'
import stepsImg from '../../../assets/images/footsteps-silhouette-variant.svg'
import loveImg from '../../../assets/images/love.svg'

const getFormattedSleepTime = x => {
    if (x == undefined) {
        return '--:--';
    }
    return new Date(x * 1000).toISOString().substr(11, 8);
}

export default function SummarySmallStatsTool(props) {
    const {
        heart_rate,
        activity,
        sleep
    } = props;

    let sleepDurationString = (sleep?.sleep_duration == undefined) ? 'N/A' : new Date(sleep.sleep_duration * 1000).toISOString().substr(11, 5);
    let stepsString = (activity?.total_steps == undefined) ? 'N/A' : activity?.total_steps;
    let bpmString = (heart_rate?.avg_daily_heart_rate == undefined) ? 'N/A' : heart_rate?.avg_daily_heart_rate;

    return (
        <Wrapper>

            <Row>
                <Badge>
                    <PictogramImg src={moonImg}/>
                    <span>
                    {sleepDurationString}
                </span>
                </Badge>

                <Badge>
                    <PictogramImg src={stepsImg}/>
                    <span>
                        {stepsString}
                    </span>
                </Badge>
            </Row>

            <Row>
                <Badge>
                    <PictogramImg src={loveImg}/>
                    <span>
                    {bpmString}
                    </span>
                </Badge>

            </Row>


        </Wrapper>
    );
}

const Wrapper = styled.div`
  opacity: 0.6;
`;

const Row = styled.div`
  font-size: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  margin-bottom: 3px;
  justify-content: center;
`;

const Badge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
`;

const PictogramImg = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;