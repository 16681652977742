import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI, {getEndpoint} from "../../../api/RonasAPI";
import {Button} from "arui-feather/button";

const getUName = u => {
    if (u == undefined) {
        return '';
    }
    let {email, firstName, lastName} = u;
    if (firstName === undefined || lastName == undefined) {
        return email;
    }
    return `${firstName}_${lastName}`;
}

export default function ExportPPGSlotDataTool(props) {
    let {slotId, user} = props;
    const [processing, setProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [done, setDone] = useState(false);
    const [status, setStatus] = useState();

    useEffect(() => {
        if (processing == false) {
            return;
        }
        makeInfinitePinging(slotId, (pr, st) => {
            setProgress(pr);
            setStatus(st);
        }).then(x => {
            setDone(true);
            setProcessing(false);
        });
    }, [processing]);

    let downUrl = `${getEndpoint()}/raw-slot-ppg-data/${slotId}/csv`;

    let downloadFileName = (user == undefined) ? undefined : getUName(user);
    if (downloadFileName != undefined) {
        downloadFileName = `${downloadFileName}_ppg.csv`;
    }

    if (downloadFileName != undefined) {
        downUrl = `${downUrl}?downloadFileName=${downloadFileName}`;
    }


    if (processing == false && done == false) {
        return (
            <Wrapper>
                <Button onClick={() => {
                    setProcessing(true);
                }}>
                    Export PPG
                </Button>
            </Wrapper>
        )
    }

    return (
        <Wrapper>

            {processing == false ? null :
                <div>
                    {status == 'queue' ? 'in queue...' : `exporting PPG... ${progress.toFixed(1)}%`}
                </div>
            }

            {done == false ? null :
                <div>
                    <a href={downUrl} target={'_blank'}>
                        download
                    </a>
                </div>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const timeout = ms => new Promise(res => setTimeout(res, ms))

async function makeInfinitePinging(slotId, onProgressCallback = (p, st) => {

                                   }
) {
    let f = false;
    while (f == false) {
        let pld = await RonasAPI.getSlotExportStatus(slotId);
        let {progress, status} = pld;
        onProgressCallback(progress, status);
        if (status == 'processed') {
            f = true;
            break;
        }
        await timeout(2000);
    }
}