export const DARK_GREEN = '#31777F';
export const LIGHT_GREEN = '#6D9CA2';
export const LIGHT_BLUE = '#1EB2FC';
export const RED = '#C35A21';

export const RONAS_DARK_GREY = '#191A21';
export const RONAS_DARK_GREEN = '#044000';
export const RONAS_LIGHT_GREEN = '#0C6102';
export const RONAS_RED = '#C90303';
export const RONAS_BLUE_MOUSE_OVER = '#1988E3';

export const getColByVal = perc => {
    if (perc == undefined) {
        return RONAS_RED;
    }
    console.log('getColByVal: perc = ', perc);
    if (perc > 90) {
        return RONAS_DARK_GREEN;
    }
    if (perc > 75) {
        return RONAS_LIGHT_GREEN;
    }
    if (perc > 60) {
        return RONAS_BLUE_MOUSE_OVER;
    }
    return RONAS_RED;
}

export const DEFAULT_DAILY_STEPS = 10000; // steps
export const DEFAULT_DAILY_DISTANCE = 8000; // meters
export const DEFAULT_DAILY_CALORIES = 600; // Cal
export const DEFAULT_DAILY_ACTIVE_MINUTES = 60; // Minutes

export const DEFAULT_SLEEP_NEED_DURATION = 8 * 60; // Minutes

export const MAX_WAKEUPS_NUMBER = 15;
