import axios from 'axios'
import moment from 'moment'

import ls from 'local-storage'

export const getEndpoint = () => {
    let s = process.env.API_ENDPOINT || `https://api.aoc.cardiomood.com`;
    // let s = process.env.API_ENDPOINT || `https://api-stg2.aoc.cardiomood.com`;
    if (window.location.href.indexOf('stg') > -1) {
        return `https://api-stg2.aoc.cardiomood.com`;
    }
    return s;
}

export const getCorsanoDashboardUrl = () => {
    let s = process.env.API_ENDPOINT || `https://portal.corsano.com`;
    if (window.location.href.indexOf('stg') > -1) {
        return `https://stg.dashboard.cardiomood.com`;
    }
    return s;
}

const RonasAPI = {

    getEndpointToCheckVersion(os) {
        return `${getEndpoint()}/app/${os}/latest`;
    },

    async getAppLatestVersion(os) {
        let version = (await axios.get(`${getEndpoint()}/app/${os}/latest`)).data.result;
        return version;
    },

    async saveAppLatestVersion(os, version) {
        await axios.post(`${getEndpoint()}/app/${os}/latest`, {
            version: `${version}`
        });
    },

    async getAllTags() {
        let tags = (await axios.get(`${getEndpoint()}/tags`)).data.result;
        return tags;
    },

    async getUserAndroidSlotsFromStartOfTheYear(id) {
        let arr = (await axios.get(`${getEndpoint()}/user/${id}/android/days`)).data.slots;
        return (arr || []);
    },

    async getUserAndroidStatusInfo(id) {
        let d = (await axios.get(`${getEndpoint()}/user/${id}/android/status-info`)).data;
        return d;
    },

    async archiveUser(id) {
        await axios.put(`${getEndpoint()}/users/${id}/archive`);
    },

    async unarchiveUser(id) {
        await axios.put(`${getEndpoint()}/users/${id}/unarchive`);
    },

    async getUserNotifications(id) {
        let d = (await axios.get(`${getEndpoint()}/health/users/${id}/notifications-logs/all`)).data.result;
        return d;
    },

    async getUserById(id) {
        let user = (await axios.get(`${getEndpoint()}/users/${id}`)).data.result;
        return user;
    },

    async deleteUser(id) {
        await axios.delete(`${getEndpoint()}/users`, {
            data: {
                id: id
            }
        });
    },

    async login(login, password) {
        console.log('login: login, password = ', login, password);
        let u = (await axios.post(`${getEndpoint()}/login`, {
            username: login.toLowerCase(),
            password: password
        })).data;
        if (u.result == undefined) {
            window.alert('Incorrect email or password')
            return;
        }
        let secret = u.result;
        ls('mmt-token', secret);
        return secret;
    },

    async getMe() {
        let u = (await axios.get(`${getEndpoint()}/me`)).data.result;
        if (u != undefined) {
            window.currentUser = u;
        }
        return u;
    },

    async getAllUsers() {
        let users = (await axios.get(`${getEndpoint()}/users`)).data.result;
        if (users != undefined) {
            users = users.map(x => ({
                ...x,
                preventicusCustomerId: (x.preventicusCustomer == undefined ? undefined : x.preventicusCustomer._id)
            }))
        }
        return users;
    },

    async getUserByEmail(email) {
        let u = (await axios.get(`${getEndpoint()}/user-by-email`, {
            params: {
                email: email
            }
        })).data.result;
        return u;
    },

    async getRawDataItemsByEmailAndDate(email, fromDateString, toDateString) {
        let u = (await axios.get(`${getEndpoint()}/ds/raw-data-items`, {
            params: {
                email: email,
                date_from: fromDateString,
                date_to: toDateString
            }
        })).data;
        return u;
    },

    async getRawDataItemsByEmailAndDatesRange(email, fromDateString, toDateString) {
        let arr = [];
        let fromS = fromDateString;
        if (+moment(toDateString) <= +moment(fromS)) {
            let sPld = await this.getRawDataItemsByEmailAndDate(email, fromS, moment(fromS).add(1, 'days').format('YYYY-MM-DD'));
            arr = arr.concat([
                {
                    date: fromS,
                    ...sPld // raw_activities, raw_sleeps, raw_rr_intervals
                }
            ]);
            return {
                email: email,
                slots: arr
            };
        }
        while (fromS != moment(toDateString).add(1, 'days').format('YYYY-MM-DD')) {
            let sPld = await this.getRawDataItemsByEmailAndDate(email, fromS, moment(fromS).add(1, 'days').format('YYYY-MM-DD'));
            console.log('-->>> sPld = ', sPld);
            arr = arr.concat([
                {
                    date: fromS,
                    ...sPld // raw_activities, raw_sleeps, raw_rr_intervals
                }
            ]);
            fromS = moment(fromS).add(1, 'days').format('YYYY-MM-DD');
        }
        return {
            email: email,
            slots: arr
        };
    },

    async getRawActivitiesByEmailAndDate(email, fromDateString, toDateString) {
        let u = (await axios.get(`${getEndpoint()}/ds/raw-activities`, {
            params: {
                email: email,
                date_from: fromDateString,
                date_to: toDateString
            }
        })).data;
        return u;
    },

    async getMonthUserSummaries(email, monthTimestamp) {
        let date = moment(monthTimestamp).format('YYYY-MM-DD');
        let pld = (await axios.get(`${getEndpoint()}/ds/month-summaries`, {
            params: {
                date: date,
                email: email
            }
        })).data;
        return pld;
    },

    async getDaySummary(uuid, dayTimestamp) {
        let date = moment(dayTimestamp).format('YYYY-MM-DD');
        let pld = (await axios.get(`${getEndpoint()}/ds/user/${uuid}/day-summary`, {
            params: {
                date: date,
                with_slots: 1
            }
        })).data;
        return pld;
    },

    async getRawMetricsByEmailAndDatesRange(email, fromDateString, toDateString) {
        let arr = [];
        let fromS = fromDateString;
        if (+moment(toDateString) <= +moment(fromS)) {
            return [];
        }
        while (fromS != moment(toDateString).add(1, 'days').format('YYYY-MM-DD')) {
            let dArr = await this.getRawActivitiesByEmailAndDate(email, fromS, moment(fromS).add(1, 'days').format('YYYY-MM-DD'));
            arr = arr.concat([
                {
                    date: fromS,
                    points: dArr
                }
            ]);
            fromS = moment(fromS).add(1, 'days').format('YYYY-MM-DD');
        }
        return {
            email: email,
            slots: arr
        };
    },

    async getRawDataForManyUsersForRange(emails = [], fromDateString, toDateString, onProgress = (p, ema) => {

    }) {
        let arr = [];
        if (emails.length == 0) {
            return [];
        }
        for (let i in emails) {
            let em = emails[i];
            onProgress(100.0 * i / emails.length, em);
            let x = await this.getRawMetricsByEmailAndDatesRange(em, fromDateString, toDateString);
            arr.push(x);
        }
        return arr;
    },

    async getRawDataForManyUsersForRange2(emails = [], fromDateString, toDateString, onProgress = (p, ema) => {

    }) {
        console.log('--->>>> getRawDataForManyUsersForRange2');
        let arr = [];
        if (emails.length == 0) {
            return [];
        }
        for (let i in emails) {
            let em = emails[i];
            onProgress(100.0 * i / emails.length, em);
            let x = await this.getRawDataItemsByEmailAndDatesRange(em, fromDateString, toDateString);
            console.log('zz x = ', x);
            arr.push(x);
        }
        return arr;
    },


    async saveUser(user) {
        let u = (await axios.put(`${getEndpoint()}/add-user`, {
            ...user
        })).data.result;
        return u;
    },

    async sendPush(uuid, title, body) {
        let u = (await axios.post(`${getEndpoint()}/users/${uuid}/notify`, {
            title: title,
            body: body
        })).data.result;
        return u;
    },

    async getFirebaseTokens(uuid) {
        let u = (await axios.get(`${getEndpoint()}/users/${uuid}/device-tokens`)).data.result.device_tokens;
        return u;
    },

    async getUserMonthDays(userId, monthDate) {
        let items = (await axios.get(`${getEndpoint()}/month-items`, {
            params: {
                date: monthDate,
                userId: userId
            }
        })).data.result;
        return items;
    },

    async getUserDayItems(userId, dayDate) {
        let items = (await axios.get(`${getEndpoint()}/day-items`, {
            params: {
                date: dayDate,
                userId: userId
            }
        })).data.result;
        return items;
    },

    async getUserInfoFromBothClouds(uuid) {
        let pld = (await axios.get(`${getEndpoint()}/users/${uuid}/uh-users`)).data.result;
        return pld;
    },

    async getUserTokensInfo(uuid) {
        let pld = (await axios.get(`${getEndpoint()}/users/${uuid}/uh-tokens`)).data.result;
        return pld;
    },


    async getUserDayItemsBySlotId(slotId) {
        let items = (await axios.get(`${getEndpoint()}/day-items`, {
            params: {
                slotId: slotId
            }
        })).data.result;
        return items;
    },

    async getRawDataFor5Minutes(slotId, minutes) {
        let items = (await axios.get(`${getEndpoint()}/raw-data-5-min`, {
            params: {
                slotId: slotId,
                minutes: minutes
            }
        })).data.result;
        return items;
    },

    async getUserLogsForRange(userId, from, to) {
        let items = (await axios.get(`${getEndpoint()}/health/users/${userId}/logs`, {
            params: {
                from: +from - 3 * 3600000,
                to: to + 3 * 3600000
            }
        })).data.result;
        return items;
    },

    async getSequencesForInRange(userId, from, to) {
        let items = (await axios.get(`${getEndpoint()}/health/users/${userId}/sequences`, {
            params: {
                from: +from - 3 * 3600000,
                to: to + 3 * 3600000
            }
        })).data.result;
        return items;
    },

    async getPreventicusCustomer(userId) {
        let pld = (await axios.get(`${getEndpoint()}/health/users/${userId}/preventicus`)).data.result;
        return pld;
    },

    async getUserDevices(userId) {
        let pld = (await axios.get(`${getEndpoint()}/health/users/${userId}/devices`)).data.result;
        return pld;
    },

    async getSequenceUploadLogs(id) {
        let pld = (await axios.get(`${getEndpoint()}/health/sequences/${id}/upload-logs`)).data.result;
        return pld;
    },

    async resetPwd(code, email) {
        await axios.post(`${getEndpoint()}/reset-pwd`, {
            code: code,
            email: email
        });
    },

    async restorePwd(emailCode, pwd) {
        await axios.post(`${getEndpoint()}/reset-pwd-step-2`, {
            emailCode: emailCode,
            password: pwd
        });
    },

    async getSlotExportStatus(slotId) {
        let pld = (await axios.get(`${getEndpoint()}/raw-slot-ppg-data`, {
            params: {
                slotId: slotId
            }
        })).data.result;
        return pld;
    },

    async getZobinImageId(uuid, start, end) {
        let pld = (await axios.get(`${getEndpoint()}/zobin/user/${uuid}/imageId`, {
            params: {
                start: start,
                end: end
            }
        })).data.result;
        return pld;
    },


}

export default RonasAPI;


// POST: /ping

// {
//     deviceMetrics: {
//         battery: 90
//     },
//     phoneMetrics: {
//         battery: 100,
//         brand: "Samsng"
//
//     }
// }
