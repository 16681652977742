import JSZip from 'jszip'

import {saveAs} from 'file-saver';

const gs = s => (s == undefined ? '' : s);

const getCsvFromArr = (arr, fields) => {
    let s = `timestamp;date;${fields.join(';')}`;
    for (let i in arr) {
        let a = arr[i];
        let s2 = `${a.timestamp};${a.date_without_tz};${fields.map(x => gs(a[x])).join(';')}`;
        s = `${s}\n${s2}`;
    }
    return s;
}

const isInRange = (point, from, to) => {
    // console.log('>> isInRange: point = ', point);
    if (point == undefined || point.timestamp == undefined) {
        return false;
    }
    let {timestamp} = point;
    // console.log('---->>> isInRange: timestamp = ', timestamp);
    return ((+timestamp >= +from) && (+timestamp < +to));
}

const getFilteredPoints = (points = [], from, to) => {
    console.log('getFilteredPoints: from, to, points  = ', from, to, points);
    let filteredPoints = points.filter(x => isInRange(x, from, to));
    console.log(`filteredPoints.length = ${filteredPoints.length}`);
    return filteredPoints;
}

const getMergedSlots = (oldSlots, newDateName = 'folder') => {
    let new_raw_rr_intervals = [], new_raw_activities = [], new_raw_sleeps = [], new_raw_temperatures = [];
    for (let i in oldSlots) {
        let sl = oldSlots[i];
        let {
            raw_rr_intervals = [],
            raw_activities = [],
            raw_sleeps = [],
            raw_temperatures = []
        } = sl;
        new_raw_rr_intervals = new_raw_rr_intervals.concat(raw_rr_intervals);
        new_raw_activities = new_raw_activities.concat(raw_activities);
        new_raw_sleeps = new_raw_sleeps.concat(raw_sleeps);
        new_raw_temperatures = new_raw_temperatures.concat(raw_temperatures);
    }
    if (oldSlots.length == 0) {
        return (
            [
                {
                    date: newDateName,
                    raw_rr_intervals: [],
                    raw_activities: [],
                    raw_sleeps: [],
                    raw_temperatures: []
                }
            ]
        )
    }
    return [
        {
            date: `${oldSlots[0].date} - ${oldSlots[oldSlots.length - 1].date}`,
            raw_rr_intervals: new_raw_rr_intervals,
            raw_activities: new_raw_activities,
            raw_sleeps: new_raw_sleeps,
            raw_temperatures: new_raw_temperatures
        }
    ]
}


const preprocessActivity = (activityPoints = []) => {
    let arr = activityPoints;
    arr = arr.map(x => {
        if (x.respiration_rate == undefined || window.isNaN(x.respiration_rate) == true) {
            return x;
        }
        return {
            ...x,
            respiration_rate: +(+x.respiration_rate / 4.0).toFixed(1),
            energy_expenditure: x.step_calories
        }
    })
    return arr;
}

const ExportHelper = {

    async exportUsersStats(arr, zipName = `session.zip`) {
        let zip = new JSZip();
        let rootFolder = zip.folder(zipName.replace('.zip', ''));
        for (let x of arr) {
            let {email, slots = []} = x;
            let emailFolder = rootFolder.folder(email);
            if (slots.length == 0) {
                continue;
            }
            for (let sl of slots) {
                let {date, points} = sl;
                let dateFolder = emailFolder.folder(date);
                // dateFolder.file('raw_metrics.json', JSON.stringify(points));
                dateFolder.file('sleep.csv', 'timestamp;date;sleep_state\n');
                // dateFolder.file('ppg_acc.csv', 'timestamp;date;ppg;acc\n');
                dateFolder.file('rr.csv', 'timestamp;date;rr\n');
                dateFolder.file('activity.csv', getCsvFromArr(points, ['bpm', 'last_steps', 'activity_type', 'speed', 'skin_proximity', 'energy_exp', 'respiration_rate', 'battery', 'energy_expenditure', 'rest_calories', 'step_duration', 'temp_sk1', 'temp_sk2', 'temp_amb']));
            }
        }
        let content = await zip.generateAsync({type: "blob"});
        saveAs(content, zipName);
    },

    async exportUsersStatsExtended(arr, zipName = `session.zip`, shouldMergeDays = false, from = 0, to = Math.round((+new Date()) * 1.2)) {
        console.log('exportUsersStatsExtended: arr = ', arr);
        let zip = new JSZip();
        let rootFolder = zip.folder(zipName.replace('.zip', ''));
        for (let x of arr) {
            if (shouldMergeDays == true) {
                x.slots = getMergedSlots(x.slots == undefined ? [] : x.slots);
            }
            let {email, slots} = x;
            let emailFolder = rootFolder.folder(email);
            for (let sl of slots) {
                let {
                    date,
                    points,
                    raw_rr_intervals = [],
                    raw_activities = [],
                    raw_sleeps = [],
                    raw_temperatures = []
                } = sl;
                let dateFolder = emailFolder.folder(date);

                // dateFolder.file('ppg_acc.csv', '');
                dateFolder.file('rr.csv', getCsvFromArr(getFilteredPoints(raw_rr_intervals, from, to), ['rr']));
                dateFolder.file('temperature.csv', getCsvFromArr(getFilteredPoints(raw_temperatures, from, to), ['temp_sk1', 'temp_sk2', 'temp_amb']));
                dateFolder.file('activity.csv', getCsvFromArr(preprocessActivity(getFilteredPoints(raw_activities, from, to)), ['bpm', 'bpm_q', 'pha', 'pha_q', 'last_steps', 'activity_type', 'speed', 'skin_proximity', 'energy_exp', 'respiration_rate', 'resp_q', 'move_alert', 'battery', 'step_calories', 'distance']));
                dateFolder.file('sleep.csv', getCsvFromArr(getFilteredPoints(raw_sleeps, from, to), ['sleep_state', 'sleep_q']));

            }
        }
        let content = await zip.generateAsync({type: "blob"});
        saveAs(content, zipName);
    }

}


export default ExportHelper;

