import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import useDimensions from "react-use-dimensions";

import {LineChart, XAxis, YAxis, CartesianGrid, Line} from 'recharts'
import './style.css'
import DrawHelper from "../../../helpers/DrawHelper";

export default function SimpleBpmSlotsChart(props) {
    const {
        slots = [],
        min = 0,
        max = +new Date() + 86400000,
    } = props;
    const [ref, {x, y, width, height}] = useDimensions();
    console.log('SimpleBpmSlotsChart: slots = ', slots);
    // let fPoints = slots.filter(x => (+x.t >= +min && +x.t < +max));
    let fPoints = slots;

    console.log('SimpleBpmSlotsChart: slots = ', slots);
    console.log('fPoints = ', fPoints);
    if (fPoints.length == 0) {
        return (
            <div>
                no data
            </div>
        )
    }

    // fPoints = [{bpm: fPoints[0].bpm, t: +min}].concat(fPoints).concat([{bpm: fPoints[fPoints.length - 1].bpm, t: +max}])
    // fPoints = [{bpm: null, t: +min}].concat(fPoints).concat([{bpm: null, t: +max}])

    // if (preserveMinMax == true) {
    //     fPoints = [{bpm: null, t: +min, date: moment(+min).format('HH:mm')}].concat(fPoints).concat([{bpm: null, t: +max, date: moment(+max).format('HH:mm')}])
    // }
    //

    // fPoints = DrawHelper.getMonotoneForAxisHeartRatePoints(fPoints, min, max);

    console.log('transformed points = ', fPoints);

    return (
        <Wrapper ref={ref}>
            <LineChart width={width} height={height} data={fPoints} connectNulls={true}
                       margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                <XAxis dataKey="date" tickCount={25}
                       domain={[min, max]}

                    // tickFormatter={a => {
                    //     return (moment(a).format('HH:mm'))
                    // }}

                />
                <YAxis/>
                <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                <Line type="monotone" dataKey="bpm" stroke="#D92A3E" strokeWidth={2} animationDuration={1}
                      dot={false}/>
            </LineChart>
        </Wrapper>
    );
}

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;


const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;