import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Button} from "arui-feather/button";
import RonasAPI from "../../api/RonasAPI";

export default function ResetPwdTool(props) {
    const {code} = props;
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [emailCode, setEmailCode] = useState('');

    const [loading, setLoading] = useState(false);

    const [mode, setMode] = useState('start');

    return (
        <Wrapper>

            <Field>
                <Label>
                    Voucher Code
                </Label>
                <div>
                    {code}
                </div>
            </Field>

            <Field>
                <Label>
                    Administrator email
                </Label>
                <div>
                    {mode != 'start' ? email :
                        <Input value={email} onChange={evt => {
                            setEmail(evt.target.value);
                        }}/>
                    }

                </div>
            </Field>


            {mode != 'reset' ? null :
                <Field>
                    <Label>
                        Code from the inbox
                    </Label>
                    <Input value={emailCode} onChange={evt => {
                        setEmailCode(evt.target.value);
                    }}/>
                </Field>
            }

            {mode != 'reset' ? null :
                <Field>
                    <Label>
                        New Password
                    </Label>
                    <Input value={password} onChange={evt => {
                        setPassword(evt.target.value);
                    }}/>
                </Field>
            }






            {mode != 'start' ? null :
                <Field>

                    <Spin visible={loading}/>

                    {loading == true ? null :
                        <Button onClick={async () => {
                            setLoading(true);
                            await RonasAPI.resetPwd(code, email);
                            setLoading(false);
                            setMode('reset');
                        }}>
                            {`Send reset message to ${email}`}
                        </Button>
                    }

                </Field>
            }

            {mode != 'reset' ? null :
                <Field>

                    <Spin visible={loading}/>

                    {loading == true ? null :
                        <Button onClick={async () => {
                            setLoading(true);
                            await RonasAPI.restorePwd(emailCode, password);
                            setLoading(false);
                            setMode('finish');
                        }}>
                            {`Reset password!`}
                        </Button>
                    }

                </Field>
            }

            {mode != 'finish' ? null :
                <Field>
                    Success!
                </Field>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 5px;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding-left: 5px;
  height: 40px;
  outline: none;
  border: 1px solid lightgrey;
  font-size: 18px;

  :focus {
    border-color: #8bc9a7;
  }
`;

