import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../../api/RonasAPI";
import PushNotificationTool from "../../dashboard/tools/PushNotificationTool";

const getSortedArr = arr => {
    return arr.sort((a, b) => {
        let t1 = +moment(a.created_at);
        let t2 = +moment(b.created_at);
        return +t2 - +t1;
    })
}


export default function NotificationsPanel(props) {
    const {id} = props;
    const [points, setPoints] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setPoints([]);
        RonasAPI.getUserNotifications(id).then(arr => {
            setPoints(getSortedArr(arr.filter(x => (x.data != undefined && x.data.state == 'notification_sent'))));
            setLoading(false);
        })
    }, [id]);

    if (points.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            <NotificationsList>
                {points.map((p, i) => {
                    let {data, created_at} = p;
                    let label = {
                        oac_study_absence_of_data_regular: 'Regular check',
                        oac_study_absence_of_data_morning: 'Morning check',
                        oac_study_absence_of_data_evening: 'Evening check',
                    }[data.notification_type];
                    if (label == undefined) {
                        label = data.notification_type;
                    }
                    return (
                        <NotificationItem key={p._id}>
                            <Left>
                                {label}
                            </Left>
                            <Right>
                                {created_at}
                            </Right>
                        </NotificationItem>
                    )
                })}
            </NotificationsList>

            <div>
                <PushNotificationTool uuid={id}/>
            </div>

        </Wrapper>
    );
}


const Wrapper = styled.div`

`;

const NotificationsList = styled.div`

`;

const NotificationItem = styled.div`
  padding: 10px;
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  margin-bottom: 10px;
`;

const Left = styled.div`

`;

const Right = styled.div`

`;
