import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";
import {Button} from "arui-feather/button";

export default function DoctorForm(props) {

    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [tag, setTag] = useState(props.tag == undefined ? '' : props.tag);
    const [email, setEmail] = useState(props.email == undefined ? '' : props.email);
    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);
    const [logoSrc, setLogoSrc] = useState(props.logoSrc);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Client Email (must be an existing user)
                </Label>
                <Input value={email} onChange={evt => {
                    setEmail(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Study tag
                </Label>
                <Input value={tag} onChange={evt => {
                    setTag(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Company name
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Client description
                </Label>
                <Input value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Company logo
                </Label>
                {logoSrc == undefined ?
                    <div>
                        <SimpleUploadPanel onUploaded={s => {
                            setLogoSrc(s);
                        }}>
                                <span>
                                    upload logo image (transparent .png)
                                </span>
                        </SimpleUploadPanel>
                    </div>
                    :
                    <div>
                        <div>
                            <img style={{height: 160}} src={logoSrc}/>
                        </div>
                        <div>
                            <SimpleUploadPanel onUploaded={s => {
                                setLogoSrc(s);
                            }}>
                                <span>
                                    upload different logo
                                </span>
                            </SimpleUploadPanel>
                        </div>
                    </div>
                }
            </Field>

            <Field>
                <Spin visible={loading} />
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave({
                            logoSrc,
                            name,
                            description,
                            email,
                            tag
                        })
                    }}>
                        Save
                    </Button>
                }
            </Field>

        </Wrapper>
    );
}


const Wrapper = styled.div`

`;

const Input = styled.input`
  outline: none;
  border: 1px solid whitesmoke;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  
  :focus {
    border-color: blue;
  }
`;

const Textarea = styled.textarea`
  outline: none;
  border: 1px solid whitesmoke;
  box-sizing: border-box;
  min-height: 4em;
  width: 100%;

  :focus {
    border-color: blue;
  }
`;


const Field = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-weight: bold;
`;