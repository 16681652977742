import React, {useState} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'

import DateTimePicker from 'react-datetime-picker';
import {Button} from "arui-feather/button";
import RonasAPI from "../../../api/RonasAPI";
import ProgressBar from 'arui-feather/progress-bar';
import ExportHelper from "../../../helpers/ExportHelper";


export default function ExportDataTool(props) {
    const {
        users = []
    } = props;

    const [from, setFrom] = useState(+moment().add(-3, 'days').startOf('day'));
    const [to, setTo] = useState(+moment().endOf('day'));

    const [exporting, setExporting] = useState(false);
    const [exported, setExported] = useState(false);

    const [exportingProgress, setExportingProgress] = useState(0);

    return (
        <Wrapper>
            <Heading>
                {`Exporting data of ${users.length} user${users.length > 1 ? 's' : ''}`}
            </Heading>
            <ContentPlaceholder>

                {exported == true ? null :
                    <Brow>
                        <Half>
                            <Label>
                                From
                            </Label>
                            <DateTimePicker
                                value={new Date(from)}
                                onChange={d => {
                                    setFrom(+new Date(d));
                                }}/>
                        </Half>
                        <Half>
                            <Label>
                                To
                            </Label>
                            <DateTimePicker
                                value={new Date(to)}
                                onChange={d => {
                                    setTo(+new Date(d));
                                }}/>
                        </Half>
                    </Brow>
                }

                {exported == true ? null :
                    <Row>
                        <Spin visible={exporting}/>
                        {exporting == true ? null :
                            <Button onClick={async () => {
                                setExporting(true);
                                setExportingProgress(0);
                                let pld = await RonasAPI.getRawDataForManyUsersForRange2(users.map(x => x.email), moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD'), progress => {
                                    setExportingProgress(progress);
                                });
                                setExportingProgress(100);
                                setExported(true);
                                setExporting(false);
                                await ExportHelper.exportUsersStatsExtended(pld, `${moment(from).format('YYYY-MM-DD')}_${moment(to).format('YYYY-MM-DD')}`);
                            }}>
                                {'Export metrics'}
                            </Button>
                        }

                        {/*<Button onClick={async () => {*/}
                        {/*    // todo: Sabir*/}
                        {/*}}>*/}
                        {/*    {'Export PPG'}*/}
                        {/*</Button>*/}
                    </Row>
                }

                {exporting == false ? null :
                    <div style={{marginTop: 20}}>
                        <ProgressBar percent={exportingProgress}/>
                    </div>
                }

                {/*{exported == false ? null :*/}
                {/*    <div style={{marginTop: 20}}>*/}
                {/*        The archive with data will be sent to your inbox*/}
                {/*    </div>*/}
                {/*}*/}

            </ContentPlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Row = styled.div`

`;

const Label = styled.div`
  font-weight: bold;
`;

const Half = styled.div`
  flex: 1;
`;

const Brow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;


const Heading = styled.div`
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 10px;
`;

const ContentPlaceholder = styled.div`
  margin-top: 20px;
`;