import axios from 'axios'

import ls from 'local-storage'

const getEndpoint = () => {
    let s = process.env.API_ENDPOINT || `https://api.aoc.cardiomood.com`;
    if (window.location.href.indexOf('stg') > -1) {
        return `https://api-stg2.aoc.cardiomood.com`;
    }
    return s;
}

const StudyCustomersAPI = {

    async createCustomer(data) {
        let s = (await axios.post(`${getEndpoint()}/user/${data.mmtUserUUID}/study-customers`, data)).data.result;
        return s;
    },

    async getUserSessions(mmtUserUUID) {
        let s = (await axios.get(`${getEndpoint()}/study/users/${mmtUserUUID}/trials-sessions`)).data.result;
        return s;
    },

    async updateSession(id, data) {
        let pld = (await axios.put(`${getEndpoint()}/study/trials-sessions/${id}`, data)).data;
        return pld;
    },


    async createSession(mmtUserUUID, d) {
        let s = (await axios.post(`${getEndpoint()}/study/users/${mmtUserUUID}/trials-sessions`, {
            ...d
        })).data.result;
        return s;
    },

    async getUserCustomers(mmtUserUUID) {
        let s = (await axios.get(`${getEndpoint()}/user/${mmtUserUUID}/study-customers`)).data.result;
        return s;
    },

    async updateCustomer(data) {
        let s = (await axios.put(`${getEndpoint()}/study-customers`, data)).data.result;
        return s;
    },

    async deleteCustomer(id) {
        await axios.delete(`${getEndpoint()}/study-customers`, {data: {id: id}});
    },


}

export default StudyCustomersAPI;

