import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../../api/RonasAPI";

export default function PreventicusCustomerStatusSpan(props) {
    const {id} = props;
    const [data, setData] = useState(undefined);

    useEffect(() => {
        RonasAPI.getPreventicusCustomer(id).then(pld => {
            setData(pld);

        });
    }, [id]);

    if (data == undefined) {
        return null;
    }

    let status = data.activation_status;

    return (
        <Wrapper >
            <div>
                {`Preventicus status: ${status}`}
            </div>
            <div>
                {`Preventicus ID: ${data._id}`}
            </div>
            <div>
                {`Preventicus  UUID: ${data.uuid}`}
            </div>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-top: 20px;
`;
