import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import DrawHelper from "../../../helpers/DrawHelper";

export default function SleepViewTool(props) {
    const {slots = [], ticksNumber = 8, noDataText = '...', loading} = props;

    const optSlots = DrawHelper.getGroupedSleepPoints(slots);
    console.log('optSlots = ', optSlots);

    if (optSlots.length == 0) {
        return (
            <NoDataPlaceholder>
                <NoDataText>
                    {noDataText}
                </NoDataText>
            </NoDataPlaceholder>
        )
    }

    let ticks = Array.from({length: ticksNumber + 1}).map((x, i) => ({left: 100.0 * i / ticksNumber}));
    const t0 = optSlots[0].from;
    let w = Math.round(1.0 * (optSlots[optSlots.length - 1].to - optSlots[0].from));
    ticks = ticks.map(x => ({...x, t: t0 + Math.round(w * x.left / 100.0)}))

    return (
        <Wrapper>

            <LeftPlaceholder>
                <LeftInnerPlaceholder>
                    {['DEEP', 'LIGHT', 'REM', 'AWAKE'].map((x, i) => <VertAxisLabel key={`${x}`} height={+i + 1}>{x}</VertAxisLabel>)}
                </LeftInnerPlaceholder>
            </LeftPlaceholder>

            <RightPlaceholder>

                <TopPlaceholder>
                    <ChartPlaceholder>
                        {[1, 2, 3, 4].map(x => <HorLine key={x} height={x} />)}
                        {optSlots.map((opt, i) => {
                            return (
                                <Box {...opt} key={`opt_${i}_${opt.width_percent}`}>

                                </Box>
                            )
                        })}
                    </ChartPlaceholder>
                </TopPlaceholder>

                <BottomPlaceholder>
                    {ticks.map((x, i) => {
                        return (
                            <Tick key={i} left={x.left}>
                                <TickInner>
                                    {moment(x.t).format('HH:mm')}
                                </TickInner>
                            </Tick>
                        )
                    })}
                </BottomPlaceholder>

            </RightPlaceholder>


            {/*{JSON.stringify(optSlots)}*/}
        </Wrapper>
    );
}

const bottomHeight = 14;

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const BORDER_COLOR = 'lightgrey';

const Tick = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: ${props => `${props.left}%`};
`;

const TickInner = styled.div`
  width: 30px;
  box-sizing: border-box;
  text-align: center;
  margin-left: -15px;
  font-size: 10px;
  margin-top: 2px;
  background: white;

  ${Tick}:last-of-type > & {
    margin-left: -20px;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-right: 1px solid ${BORDER_COLOR};
`;

const HorLine = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  background: ${BORDER_COLOR};
  height: 1px;
  bottom: ${props => (`calc(${100.0 * props.height / 4.0}% - 1px)`)};
  z-index: 1;
`;

const Box = styled.div`
  position: absolute;
  bottom: 0px;
  height: ${props => (`${100.0 * props.height / 4.0}%`)};
  width: ${props => (`${props.width_percent}%`)};
  left: ${props => (`${props.left_percent}%`)};
  box-sizing: border-box;
  background: ${props => props.color};
  z-index: 3;
`;

const LeftPlaceholder = styled.div`
  width: 40px;
  border-right: 1px solid ${BORDER_COLOR};
`;

const LeftInnerPlaceholder = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const VertAxisLabel = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  height: 12px;
  font-size: 10px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 3px;
  bottom: ${props => (`calc(${100.0 * props.height / 4.0}% - 10px + ${bottomHeight}px)`)};
  z-index: 1;
  :last-of-type{
    bottom: calc(100% - 6px);
  }
`;

const RightPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TopPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  background: white;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const BottomPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${bottomHeight}px;
  background: white;
  padding: 2px;
  border-top: 1px solid ${BORDER_COLOR};
  position: relative;
`;
