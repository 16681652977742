import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../api/RonasAPI";

import bgImage from '../../assets/images/CBackground.jpg'

export default function LoginApp(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    console.log('--->>> render: LoginApp');

    return (
        <Wrapper>

            <Box>

                <Field>
                    <Input placeholder={'username'}
                           value={username}
                           onChange={evt => {
                               setUsername(evt.target.value.replace(/ /g, ''));
                           }}/>
                </Field>

                <Field>
                    <Input placeholder={'password'}
                           type={'password'}
                           value={password}
                           onChange={evt => {
                               setPassword(evt.target.value.replace(/ /g, ''));
                           }}/>
                </Field>

                <div>
                    <Button onClick={async () => {
                        setLoading(true);
                        let x = await RonasAPI.login(username, password);
                        setLoading(false);
                        if (x != undefined) {
                            window.location.reload();
                        }
                    }}>
                        <Spin visible={loading} theme={'alfa-on-color'}/>
                        {loading == true ? null :
                            'Sign in'
                        }
                    </Button>
                </div>

            </Box>

        </Wrapper>
    );
}

const Field = styled.div`
    margin-bottom: 10px;
`;

const Button = styled.div`
    box-sizing: border-box;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(243deg,#8bc9a7 0%,#7de0b5 100%);
    color: white;
    cursor: pointer;
    opacity: 0.99;
    border-radius: 4px;
    font-size: 18px;
    :hover{
      opacity: 0.9;
    }
`;

const Input = styled.input`
    box-sizing: border-box;
    width: 100%;
    padding-left: 5px;
    height: 40px;
    outline: none;
    border: none;
    border-bottom: 1px solid lightgrey;
    font-size: 18px;
    :focus{
      border-color: #8bc9a7;
    }
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-image: url(${bgImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Box = styled.div`
    width: 380px;
    box-sizing: border-box;
    padding: 20px;
    background: rgba(255,255,255,0.95);
    border: 1px solid lightgrey;
    border-radius: 4px;
`;
