import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import arrowImg from '../../../assets/images/right-arrow-angle.svg'
import SummarySmallStatsTool from "../../summaries/tools/SummarySmallStatsTool";

export function perc2color(perc) {
    let r, g, b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    } else {
        g = 255;
        r = Math.round(510 - 5.10 * perc);
    }
    let h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}


export default function HeatCalendar(props) {
    const {
        selectedDayTimestamp,
        monthTimestamp,
        onMonthChange = ttt => {

        },
        onDayClick = ttt => {

        },
        monthSummariesMap = {},
        days = [], // [{date: "2020-03-01", value: 45123}, ...]
        maxValue = 25 * 60 * 60 * 24 // max number of value
    } = props;

    let startT = +moment(monthTimestamp).startOf('month').startOf('isoweek').startOf('day');
    let endT = +moment(monthTimestamp).endOf('month').endOf('isoweek').endOf('day');
    let weeksNumber = Math.round((endT - startT) / (86400000.0 * 7));
    let weeks = Array.from({length: weeksNumber})
        .map((a, i) => +startT + i * 7 * 86400000)
        .map((x, i) => Array.from({length: 7}).map((y, j) => (+x + 86400000 * j)));

    console.log('HeatCalendar: render: days = ', days);
    console.log('weeks = ', weeks);

    return (
        <Wrapper>

            <MonthTopPlaceholder>

                <MonthArrowIcon
                    style={{transform: 'rotate(180deg)'}}
                    src={arrowImg}
                    onClick={() => {
                        onMonthChange(+moment(monthTimestamp).add(-1, 'months'))
                    }}
                />
                <MonthName>
                    {moment(monthTimestamp).format('MMMM YYYY')}
                </MonthName>
                <MonthArrowIcon
                    src={arrowImg}
                    onClick={() => {
                        onMonthChange(+moment(monthTimestamp).add(1, 'months'))
                    }}
                />

            </MonthTopPlaceholder>

            <WeekRow>
                {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((a, i) => {
                    return (
                        <WeekDayName key={a}>
                            {a}
                        </WeekDayName>
                    )
                })}
            </WeekRow>

            {weeks.map((x, i) => {
                return (
                    <WeekRow key={`ee${monthTimestamp}_${i}_${x[0]}`}>
                        {x.map((t, j) => {
                            // let vv = days.filter(q => (t == +moment(q.date, 'YYYY-MM-DD').startOf('day')))[0];
                            let vv = days.filter(q => (moment(t).format('YYYY-MM-DD') == q.date))[0];


                            let isVoid = (vv == undefined);
                            let perc = (isVoid == true) ? 0 : Math.min((100.0 * vv.value / maxValue), 100.0);
                            let bgColor = (isVoid == true) ? 'transparent' : perc2color(perc);
                            let isDaySelected = (selectedDayTimestamp != undefined && (+moment(selectedDayTimestamp).startOf('day') == +t))
                            let sDate = moment(t).format('YYYY-MM-DD');
                            let mSum = (monthSummariesMap == undefined) ? undefined : monthSummariesMap[sDate];

                            return (
                                <Day key={t} selected={isDaySelected}
                                     style={{cursor: (isVoid == true) ? 'default' : 'pointer'}}
                                     onClick={() => {
                                         onDayClick(t, (vv == undefined) ? undefined : vv._id);
                                     }}>
                                    <DatePlaceholder today={+moment().startOf('day') == +t}>
                                        {moment(t).format('D')}
                                    </DatePlaceholder>
                                    <DayInner bgColor={bgColor}>
                                        {isVoid == true ? null :
                                            <React.Fragment bgColor={bgColor}>
                                            <span style={{fontSize: 16, fontWeight: 'bold'}}>
                                                {`${perc.toFixed(1)}%`}
                                            </span>
                                                <span style={{fontSize: 10}}>
                                                ({vv.value})
                                            </span>
                                            </React.Fragment>
                                        }
                                        {mSum == undefined ? null :
                                            <div style={{marginTop: 15, opacity: 0.5}} >
                                                <SummarySmallStatsTool {...mSum}/>
                                            </div>
                                        }
                                    </DayInner>

                                </Day>
                            )
                        })}
                    </WeekRow>
                )
            })}

        </Wrapper>
    );
}

const MonthTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-bottom: 1px solid rgba(232, 232, 232, 0.2);
  margin-bottom: 5px;
`;

const MonthName = styled.div`
  width: 280px;
  text-align: center;
`;

const MonthArrowIcon = styled.img`
  height: 18px;
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
`;

const WeekRow = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`;

const WeekDayName = styled.div`
  flex: 1;
  opacity: 0.7;
  padding-bottom: 3px;
`;

const Day = styled.div`
  box-sizing: border-box;
  flex: 1;
  position: relative;
  height: 120px;
  border: ${props => (props.selected == true ? '3px solid blue' : '1px solid lightgrey')};
`;

const DatePlaceholder = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  opacity: 0.6;
  z-index: 2;
  padding: 3px;
  border-radius: 4px;
  font-size: ${props => (props.today == true ? 18 : 12)}px;
  font-weight: ${props => (props.today == true ? 'bold' : 'normal')};
  background: ${props => (props.today == true ? 'blue' : 'white')};
  color: ${props => (props.today == true ? 'white' : 'black')};
`;

const DayInner = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.bgColor};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
