import axios from 'axios'

import ls from 'local-storage'

const getEndpoint = () => {
    let s = process.env.API_ENDPOINT || `https://api.aoc.cardiomood.com`;
    if (window.location.href.indexOf('stg') > -1) {
        return `https://api-stg2.aoc.cardiomood.com`;
    }
    return s;
}

const NotesAPI = {

    async getNote(userId) {
        let s = (await axios.get(`${getEndpoint()}/user/${userId}/note`)).data.result;
        return s;
    },

    async saveNote(userId, message = '') {
        await axios.post(`${getEndpoint()}/user/${userId}/note`, {
            message: message
        });
    },

}

export default NotesAPI;

