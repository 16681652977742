import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../../api/RonasAPI";

export default function UpdateAppVersionTool(props) {
    const {
        label = 'Android app latest version',
        os = 'android'
    } = props;

    const [loading, setLoading] = useState(true);
    const [text, setText] = useState('');

    useEffect(() => {
        RonasAPI.getAppLatestVersion(os).then(s => {
            setText(s);
            setLoading(false);
        })
    }, [os]);

    return (
        <Wrapper>

            <Label>
                {label}
            </Label>
            <InputPlaceholder>
                <Input value={text} onChange={evt => {
                    setText(evt.target.value);
                }}/>
                <Button onClick={async () => {
                    setLoading(true);
                    await RonasAPI.saveAppLatestVersion(os, text);
                    setLoading(false);
                }}>
                    <Spin visible={loading}/>
                    Save
                </Button>
            </InputPlaceholder>

            <div style={{fontStyle: 'italic', fontSize: 12}}>
                {`API to get version: GET ${RonasAPI.getEndpointToCheckVersion(os)}`}
            </div>


        </Wrapper>
    );
}

const Button = styled.div`
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    background: lightgreen;
    cursor: pointer;
    margin-left: 10px;
    :hover{
      opacity: 0.9;
    }
`;

const Wrapper = styled.div`
    
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;

const InputPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Input = styled.input`
    box-sizing: border-box;
    height: 36px;
    box-sizing: border-box;
`;
