import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../api/RonasAPI";

export default function AndroidStatsTool(props) {
    const {id} = props;
    const [slots, setSlots] = useState([]);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        RonasAPI.getUserAndroidStatusInfo(id).then(d => {
            setData(d);
            RonasAPI.getUserDevices(id).then(x => {
                console.log('devices x = ', x);
            })
        })
    }, [id]);

    if (data == undefined) {
        return null;
    }

    let {showRestartButton, receivedDays, startOfMeasurementDate} = data;

    return (
        <Wrapper>
            <div>
                {`showRestartButton: ${showRestartButton}`}
            </div>
            <div>
                {`receivedDays: ${receivedDays}`}
            </div>
            <div>
                {`startOfMeasurementDate: ${startOfMeasurementDate}`}
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: normal;
    opacity: 0.5;
`;
