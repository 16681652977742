import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../../api/RonasAPI";
import SimpleBpmSlotsChart from "./SimpleBpmSlotsChart";
import SabirBarChart from "./SabirBarChart";
import DrawHelper from "../../../helpers/DrawHelper";
import SleepViewTool from "./SleepViewTool";
import {Button} from "arui-feather/button";

export default function DaySummarySmartTool(props) {
    const {
        uuid,
        type = 'activity',
        dayTimestamp
    } = props;

    const [dayData, setDayData] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDayData(undefined);
        setLoading(true);
        setDayData(undefined);
        RonasAPI.getDaySummary(uuid, dayTimestamp).then(pld => {
            setDayData(pld);
            setLoading(false);
        });
    }, [uuid, dayTimestamp, type]);

    if (loading == true || dayData == undefined) {
        return (
            <Code/>
        )
    }
    let d = dayData[type];
    console.log('dayData = ', dayData);
    console.log('d = ', d);

    if (d == undefined) {
        return (
            <div>
                no data
            </div>
        )
    }

    if (type == 'heart_rate') {
        return (
            <Wrapper style={{width: '100%', height: 400}}>
                <SimpleBpmSlotsChart {...d} />
            </Wrapper>
        )
    }

    if (type == 'activity') {
        return (
            <Wrapper style={{width: '100%', height: 400}}>
                <SabirBarChart
                    items={(d.slots == undefined) ? [] : DrawHelper.get24HoursStepsArray(d.slots)}
                    renderItem={it => <span
                        style={{
                            fontWeight: 'normal',
                            color: 'grey',
                            fontSize: 10
                        }}>{it.time}</span>}/>
            </Wrapper>
        )
    }

    if (type == 'sleep') {
        return (
            <React.Fragment>
                <Wrapper style={{width: '100%', height: '100%'}}>
                    <SleepViewTool {...d} />
                </Wrapper>
                {/*{JSON.stringify(d.slots)}*/}
                <div style={{marginTop: 20}}>
                    <Button onClick={() => {
                        let arr = (d == undefined || d.slots == undefined) ? [] : d.slots;
                        console.log(d);
                        let s = `timestamp;start_date;end_date;sleep_state\n`;
                        let s2 = arr.map(xx => `${xx.timestamp};${xx.start_date};${xx.end_date};${xx.type}`).join('\n');
                        s = `${s}${s2}`;
                        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(s);
                        let downloadAnchorNode = document.createElement('a');
                        downloadAnchorNode.setAttribute("href", dataStr);
                        downloadAnchorNode.setAttribute("download", `sleep_${moment(dayTimestamp).format('YYYY-MM-DD')}.csv`);
                        downloadAnchorNode.click();
                    }}>
                        Export sleep
                    </Button>
                </div>
            </React.Fragment>
        )
    }

    return (
        <Wrapper>
            ...
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;