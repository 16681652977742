import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import DoctorForm from "../forms/DoctorForm";
import Select from "react-select";

const API_ENDPOINT = 'https://api.prod.doctors.cardiomood.com';

export default function AllDoctorsPanel(props) {
    const [doctors, setDoctors] = useState([]);
    const [selectedId, setSelectedId] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [selectedTag, setSelectedTag] = useState(undefined);

    const [addVisible, setAddVisible] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(`${API_ENDPOINT}/doctors`).then(d => d.data.result).then(arr => {
            setDoctors(arr);
            setLoading(false);
        })
    }, []);

    if (loading == true && doctors.length == 0) {
        return (<Code/>);
    }

    let selectedDoctor = (selectedId == undefined) ? undefined : doctors.filter(x => (x.id == selectedId))[0];
    let tagsOptions = doctors.map(x => ({
        label: x.tag,
        value: x.tag
    })).filter(x => (x.value != undefined && x.value != ''))
        .reduce((mp, x) => mp.set(x.value, x), Map()).toArray().sort((a, b) => {
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
            }
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return -1;
            }
            return 0;
        });

    let fDoctors = (selectedTag == undefined) ? doctors : doctors.filter(x => (x.tag == selectedTag));

    return (
        <Wrapper>

            <Heading>
                Access to portal <a target={'_blank'} href={'https://study.corsano.com'} >study.corsano.com</a>
            </Heading>

            <BottomPlaceholder>
                <AddSpan onClick={() => {
                    setAddVisible(true);
                }}>
                    Add new doctor
                </AddSpan>
            </BottomPlaceholder>

            <ContentPlaceholder>

                <div style={{width: '100%', marginBottom: 10}}>
                    <Select options={tagsOptions}
                            isClearable={true}
                            placeholder={'Filter by tag'}
                            value={tagsOptions.filter(x => (x.value == selectedTag))[0]}
                            onChange={evt => {
                                if (evt == undefined) {
                                    setSelectedTag(undefined);
                                } else {
                                    setSelectedTag(evt.value);
                                }
                            }}
                    />
                </div>

                {fDoctors.map((d, i) => {
                    return (
                        <DoctorItem key={d.id} onClick={() => {
                            setSelectedId(d.id);
                        }}>
                            <DoctorLogo src={d.logoSrc}/>
                            <ItemRight>
                                <ItemName>
                                    {d.name}
                                    <span style={{
                                        marginLeft: 15,
                                        backgroundColor: 'green',
                                        borderRadius: 4,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        paddingLeft: 10,
                                        paddingRight: 10
                                    }}>
                                        {d.tag}
                                    </span>
                                </ItemName>
                                <div>
                                    {d.email}
                                </div>
                                {(d.description == undefined || d.description == '') ? null :
                                    <div style={{opacity: 0.5, fontSize: 12, marginTop: 10}}>
                                        {d.description}
                                    </div>
                                }
                            </ItemRight>
                        </DoctorItem>
                    )
                })}

            </ContentPlaceholder>

            <Sidebar visible={selectedDoctor != undefined} onCloserClick={() => {
                setSelectedId(undefined);
            }}>

                {selectedDoctor == undefined ? null :
                    <div>
                        <DoctorForm {...selectedDoctor} loading={loading} onSave={async d => {
                            setLoading(true);
                            await axios.put(`${API_ENDPOINT}/doctors`, {...d, id: selectedDoctor.id});
                            let arr = (await axios.get(`${API_ENDPOINT}/doctors`)).data.result;
                            setDoctors(arr);
                            setLoading(false);
                            setSelectedId(undefined);
                        }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar visible={addVisible == true} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <div>
                        <DoctorForm loading={loading} onSave={async d => {
                            setLoading(true);
                            await axios.post(`${API_ENDPOINT}/doctors`, d);
                            let arr = (await axios.get(`${API_ENDPOINT}/doctors`)).data.result;
                            setDoctors(arr);
                            setLoading(false);
                            setAddVisible(false);
                        }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const BottomPlaceholder = styled.div`
  
`;

const ContentPlaceholder = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const ItemName = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;

const DoctorItem = styled.div`
  margin-bottom: 20px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const DoctorLogo = styled.img`
  width: 180px;
  margin-right: 10px;
`;

const ItemRight = styled.div`
  flex: 1;
`;

const Wrapper = styled.div`

`;

const Heading = styled.div`
  font-size: 30px;
  margin-bottom: 20px;
`;