import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI, {getCorsanoDashboardUrl} from "../../../api/RonasAPI";

export default function TokensSpan(props) {
    const {uuid} = props;

    const [loading, setLoading] = useState(false);

    return (
        <Wrapper loading={loading} onClick={async () => {
            if (loading == true) {
                return;
            }
            setLoading(true);
            let pld = await RonasAPI.getUserTokensInfo(uuid);
            console.log('pld = ', pld);
            setLoading(false);
            let {hToken, uToken} = pld;
            let url = `${getCorsanoDashboardUrl()}/#/tokauth/${uToken}/${hToken}`;
            window.open(url, '_blank').focus();
        }}>
            {props.children}
        </Wrapper>
    );
}

const Wrapper = styled.span`
  opacity: ${props => (props.loading == true ? 0.5 : 1)};
  cursor: pointer;
`;