import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import RonasAPI from "../../../api/RonasAPI";

export default function ZobinImageToool(props) {
    const {
        uuid, start, end
    } = props;

    const [imageId, setImageId] = useState(undefined);

    useEffect(() => {
        RonasAPI.getZobinImageId(uuid, start, end).then(imId => {
            setImageId(imId);
        })
    }, [uuid, start, end]);

    // let imUrl = `http://api.cardio-smart.org/api/patient/heatmap/?rid=${imageId}`;
    let imUrl = `https://api.aoc.cardiomood.com/zimage/?rid=${imageId}`;
    if (imageId == undefined) {
        return (
            <div>
                image is not available
            </div>
        )
    }

    return (
        <Wrapper>

            <Image src={imUrl} />

            {/*<a href={imUrl} target={'_blank'}>*/}
            {/*    check image*/}
            {/*</a>*/}
        </Wrapper>
    );
}

const Wrapper = styled.div`
  margin-top: 10px;
`;

const Image = styled.img`
    width: 640px;
`;