import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

export default function Tabs(props) {
    const [activeNumber, setActiveNumber] = useState(0);
    const {
        tabs = [{
            label: 'tab1',
            content: (
                <div>
                    tab 1
                </div>
            )
        },
            {
                label: 'tab2',
                content: (
                    <div>
                        tab 2
                    </div>
                )
            }]
    } = props;

    if (tabs.length == 0) {
        return null;
    }

    const activeContent = tabs[activeNumber].content;

    return (
        <Wrapper>
            <Heading>
                {tabs.map((tb, k) => {
                    let isSelected = (+k == +activeNumber);
                    let {isExtraLink} = tb;
                    return (
                        <HeadingItem selected={isSelected} key={k} onClick={() => {
                            setActiveNumber(k);
                        }}>
                            <TabName selected={isSelected}>
                                {tb.label}
                            </TabName>
                        </HeadingItem>
                    )
                })}

            </Heading>

            <ContentPlaceholder>
                {activeContent}
            </ContentPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Heading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const HeadingItem = styled.div`
    border-color: #dee2e6 #dee2e6 #fff;
    border-width: 1px;
    margin-right: 15px;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 21px;
    background: ${props => (props.selected == true ? 'white' : 'transparent')};
    border-bottom: ${props => (props.selected == true ? `2px solid #294594` : '2px solid transparent')};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    flex-direction: row;
`;

const TabName = styled.div`
    color: ${props => (props.selected == false ? 'rgb(66, 86, 132)' : 'rgb(33, 33, 33)')};
    border-bottom: ${props => (props.selected == true ? 'none' : '1px dotted rgb(66, 86, 132)')};
`;

const ContentPlaceholder = styled.div`
    padding-top: 20px;
`;

const SupImagePlaceholder = styled.div`
    
`;

const SupLinkImg = styled.img`
    width: 10px;
    height: 10px;
    margin-left: 4px;
    vertical-align: super;
`;

function openInNewTab(url) {
    let win = window.open(url, '_blank');
    win.focus();
}

export const TabItem = styled.div`
  padding-top: 10px;
`;
