import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import arrowImage from '../../../assets/images/right-arrow-angle.svg'
import preventImg from '../../../assets/images/prevent_icon.png'
import corsImg from '../../../assets/images/CLogo-05.png'
import RawDataTool from "./RawDataTool";

import JSONPretty from 'react-json-pretty';
import RonasAPI, {getEndpoint} from "../../../api/RonasAPI";


export default function UptimeDayDetailedTool(props) {
    const {
        id, userId, slots = [],
        dayTimestamp,
        selectedSlotId,
        sequencesData = [],

        maxValuePer5Min = 25 * 60 * 5,


        onDayChange = t => {

        },
        loading = false,
        totalHeight = 500
    } = props;

    const [stepMinutes, setStepMinutes] = useState(30);
    const [selectedMinutes, setSelectedMinutes] = useState(undefined);

    const [uploadLogsData, setUploadLogsData] = useState(undefined);

    const [selectedRonasSequence, setSelectedRonasSequence] = useState(undefined);

    let rows = Array.from({length: (60 / stepMinutes)}).map((a, i) => i * stepMinutes);
    let hours = Array.from({length: 24}).map((a, i) => i);
    let maxValue = stepMinutes / 5.0 * maxValuePer5Min;

    let rSlots = slots.map(x => ({...x, h: +x.date.split(':')[0], m: +x.date.split(':')[1]})).map(x => ({
        ...x,
        t: x.h * 60 + +x.m
    }));

    let hrs = hours;
    let kk = 60 / stepMinutes; // number of slots in 1 hour
    let enhSlots = [];
    let zz = 12 / kk; // how many 5 minute slots we wave in a final slot
    let itemHeight = totalHeight / kk;

    // console.log('render: kk = ', kk);
    // console.log('render: zz = ', zz);
    // console.log('render: hours = ', hours);

    for (let i in hours) {
        let start = i * 60;
        let end = (+i + 1) * 60;
        let hItems = rSlots.filter(x => (+x.t >= start && +x.t < end));
        // console.log('hItems = ', hItems); // items in a specific hour
        let sum = 0;
        for (let j = 0; j < kk; j++) {
            let tt = +start + j * stepMinutes;
            let hh_ = Math.floor(tt / 60);
            let mm_ = tt % 60;
            let ss_ = `${`${hh_}`.padStart(2, '0')}:${`${mm_}`.padStart(2, '0')}`;
            console.log('ss_ = ', ss_);
            let sVal = hItems.filter((qq, ww) => (+ww >= j * zz && +ww < (j + 1) * zz)).reduce((sm, x) => +sm + +x.value, 0);
            enhSlots.push({
                date: ss_,
                value: sVal
            });
        }
    }

    console.log('enhSlots = ', enhSlots);
    console.log('RENDER: sequencesData = ', sequencesData);

    return (
        <Wrapper key={`${id}_${dayTimestamp}_${loading ? '2' : '1'}`}>

            <TopPlaceholder>
                {/*<Side onClick={() => {*/}
                {/*onDayChange(+moment(dayTimestamp).add(-1, 'days').startOf('day'));*/}
                {/*}}>*/}
                {/*<ArrowImg style={{transform: 'rotate(180deg)'}}*/}
                {/*src={arrowImage}/>*/}
                {/*</Side>*/}

                <CurrMonth>
                    {moment(dayTimestamp).format('MMMM, D')}
                    {loading == false ? null :
                        <span style={{marginLeft: 5}}>
                            <Spin visible={true}/>
                        </span>
                    }
                </CurrMonth>
                {/*<Side onClick={() => {*/}
                {/*onDayChange(+moment(dayTimestamp).add(1, 'days').startOf('day'));*/}
                {/*}}>*/}
                {/*<ArrowImg src={arrowImage}/>*/}
                {/*</Side>*/}
            </TopPlaceholder>

            <ContPlaceholder>
                {rows.map((a, i) => {
                    return (
                        <Row key={a}>
                            {hours.map((b, j) => {
                                let h = `${j}`.padStart(2, '0');
                                let m = `${i * stepMinutes}`.padStart(2, '0');
                                let ss = `${h}:${m}`;
                                let st = enhSlots.filter(x => (x.date == ss))[0];
                                let perc = (st == undefined) ? 0 : Math.min((100.0 * st.value / maxValue), 100.0);
                                let color = (st == undefined) ? 'transparent' : perc2color(perc);
                                let hasPreventicusInfo = (stepMinutes == 30);
                                let preventSlNum = i + j * 2;
                                let prevX = sequencesData.filter(x => (x.slotNumber == +preventSlNum))[0];
                                let ronX = sequencesData.filter(x => (x.slotNumber == +preventSlNum))[0];
                                if (prevX != undefined) {
                                    // prevX = prevX.preventicusSequence;
                                    prevX = prevX.details;
                                }
                                return (
                                    <Box key={j} color={color}
                                         style={{
                                             height: itemHeight,
                                             cursor: (st == undefined || stepMinutes != 5) ? 'default' : 'pointer'
                                         }}
                                         onClick={() => {
                                             if (st != undefined && stepMinutes == 5) {
                                                 setSelectedMinutes(ss);
                                             }
                                         }}>
                                        <div style={{fontWeight: 'bold'}}>
                                            {`${h}:${m}`}
                                        </div>
                                        {st == undefined || st.value == undefined ? null :
                                            <div style={{fontSize: 6, opacity: 0.35}}>
                                                {`${perc.toFixed(0)}%  ${st.value}`}
                                            </div>
                                        }

                                        {hasPreventicusInfo == false || ronX == undefined ? null :
                                            <div style={{
                                                marginTop: 20,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                boxSizing: 'border-box',
                                                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                                                padding: 1,
                                                borderRadius: 4,
                                                color: 'black',
                                                cursor: 'pointer'
                                            }} onClick={async () => {
                                                setSelectedRonasSequence(ronX);
                                                setUploadLogsData(undefined);
                                                let d = await RonasAPI.getSequenceUploadLogs(ronX._id);
                                                setUploadLogsData(d);
                                            }}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}>

                                                    <img src={corsImg}
                                                         style={{width: 15, margin: 2}}/>

                                                    {prevX == undefined ? null :
                                                        <img src={preventImg}
                                                             style={{width: 15, margin: 2}}/>
                                                    }

                                                </div>


                                                {prevX == undefined ? null :
                                                    <div style={{height: 24}}>
                                                        <div style={{fontSize: 7, opacity: 0.6}}>
                                                            {prevX.status}
                                                        </div>
                                                        <div>
                                                            {`${prevX.usableMinutes} m.`}
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        }

                                    </Box>
                                )
                            })}
                        </Row>
                    )
                })}
            </ContPlaceholder>

            <BottomControlsSwitcher style={{marginTop: 20}}>
                {
                    [5, 10, 15, 20, 30, 60].reverse().map((a, i) => {
                        let isSelected = (stepMinutes == a);
                        return (
                            <StepItemTab selected={isSelected} key={a} onClick={() => {
                                setStepMinutes(a);
                            }}>
                                {`${a} minutes`}
                            </StepItemTab>
                        )
                    })
                }
            </BottomControlsSwitcher>

            <Sidebar
                width={Math.min(720, window.innerWidth)}
                visible={(selectedMinutes != undefined && selectedSlotId != undefined)} onCloserClick={() => {
                setSelectedMinutes(undefined);
            }}>

                {selectedMinutes == undefined ? null :
                    <div>
                        <h3>
                            {selectedMinutes}
                        </h3>
                        <div>
                            <RawDataTool minutes={selectedMinutes} slotId={selectedSlotId}/>
                        </div>
                    </div>
                }


            </Sidebar>

            <Sidebar visible={(selectedRonasSequence != undefined)}
                     width={920}
                     onCloserClick={() => {
                         setSelectedRonasSequence(undefined);
                     }}>

                {selectedRonasSequence == undefined ? null :

                    <div>

                        {(selectedRonasSequence == undefined || selectedRonasSequence.details == undefined) ? null :
                            <div>
                                <div style={{marginTop: 30, marginBottom: 30}}>
                                    <a href={`${getEndpoint()}/preventicus/sequences/${selectedRonasSequence._id}/raw-data?name=${selectedRonasSequence.details.sequenceId}.csv`}
                                       target={'_blank'}>
                                        download raw data csv
                                    </a>
                                </div>

                                <div style={{marginTop: 30, marginBottom: 30}}>
                                    <a href={`${getEndpoint()}/preventicus/sequences/${selectedRonasSequence._id}/raw-json?name=${selectedRonasSequence.details.sequenceId}.json`}
                                       target={'_blank'}>
                                        download raw data json
                                    </a>
                                </div>
                            </div>
                        }

                        <JSONPretty id="json-pretty" data={selectedRonasSequence}></JSONPretty>


                        <div style={{marginTop: 40, paddingTop: 40, borderTop: '1px solid lightgrey'}}>
                            <h3>
                                Upload logs:
                            </h3>
                            <JSONPretty id="json-pretty" data={uploadLogsData}></JSONPretty>
                        </div>

                    </div>
                }


            </Sidebar>


        </Wrapper>
    );
}

const BottomControlsSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StepItemTab = styled.div`
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 5px;
  background: ${props => (props.selected == true ? 'blue' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  border-radius: 4px;
  border: 1px solid lightgrey;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const TopPlaceholder = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const CurrMonth = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  width: 180px;
`;

const Side = styled.div`
  cursor: pointer;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ArrowImg = styled.img`
  height: 14px;
`;

const ContPlaceholder = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const Box = styled.div`
  flex: 1;
  background: ${props => props.color};
  box-sizing: border-box;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #D5D5D5;
  height: 36px;
  text-align: center;
`;


function perc2color(perc) {
    let r, g, b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    } else {
        g = 255;
        r = Math.round(510 - 5.10 * perc);
    }
    let h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}
