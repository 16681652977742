import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RonasAPI from "../../../api/RonasAPI";

export default function RawDataTool(props) {
    const {minutes, slotId} = props;
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (minutes == undefined || slotId == undefined) {
            return;
        }
        RonasAPI.getRawDataFor5Minutes(slotId, minutes).then(arr => {
            setLoading(false);
            setItems(arr);
        });
        setLoading(true);
    }, []);

    if (minutes == undefined || slotId == undefined) {
        return null;
    }

    if (loading == true) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            <p>
                {`${items.length} points`}
            </p>

            <p>
                <ButtonSpan onClick={() => {
                    let goodFields = ['acc', 'bpm', 'bpm_q', 'crc', 'date', 'ppg', 'timestamp'];
                    let gItems = items.map(x => {
                        let xx = {};
                        for (let j in goodFields) {
                            let gf = goodFields[j];
                            xx[gf] = x[gf];
                        }
                        return xx;
                    });

                    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(gItems));
                    let downloadAnchorNode = document.createElement('a');
                    downloadAnchorNode.setAttribute("href", dataStr);
                    downloadAnchorNode.setAttribute("download", `${minutes}.json`);
                    downloadAnchorNode.click();
                }}>
                    download JSON
                </ButtonSpan>
            </p>

            {/*{JSON.stringify(items)}*/}
        </Wrapper>
    );
}

const ButtonSpan = styled.span`
    cursor: pointer;
`;

const Wrapper = styled.div`
    
`;
