import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import axios from 'axios';
import {Code} from 'react-content-loader'

// import {UPLOAD_DO_ENDPOINT_URL} from "../../constants/config";
import Dropzone, {useDropzone} from 'react-dropzone'

const UPLOAD_DO_ENDPOINT_URL = 'https://www.englishpatient.org/api/upload';

export default function SimpleUploadPanel(props) {
    const {
        onUploaded = url => {
        },
        onStart = () => {

        },
        onFail = () => {

        },
        children
    } = props;

    const onDrop = useCallback((files) => {
        console.log('onDrop occured: files = ', files);
        let file = files[0];
        const fd = new FormData();
        fd.append('file', file);
        onStart();
        axios.post(UPLOAD_DO_ENDPOINT_URL, fd).then(d => d.data.url).then(url => {
            onUploaded(url);
        }).catch(err => {
            onFail(err);
        })
    }, []);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});


    return (
        <Wrapper className={'SimpleUploadPanel_wr'} {...getRootProps()}

        >
            <input {...getInputProps()} />
            {children}
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  outline: none;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
