import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import StudyCustomersAPI from "../../api/StudyCustomersAPI";
import Tabs, {TabItem} from "../ui/Tabs";
import UpdateSessionForm from "./forms/UpdateSessionForm";
import {Button} from "arui-feather/button";
import RonasAPI from "../../api/RonasAPI";
import ExportHelper from "../../helpers/ExportHelper";
import ZobinImageToool from "../zobin/tools/ZobinImageTool";


export default function UserTrialsPanel(props) {
    const {uuid, email, name, isAdmin = false} = props;
    const [sessions, setSessions] = useState([]);

    const [selectedId, setSelectedId] = useState(undefined);
    const [addVisible, setAddVisible] = useState(false);

    const [exporting, setExporting] = useState(false);

    useEffect(() => {
        StudyCustomersAPI.getUserSessions(uuid).then(arr => {
            console.log('sessions arr = ', arr);
            setSessions(arr);
        })
    }, []);
    let uName = `${name == undefined ? email.split('@')[0] : name.replace(/ /g, '_')}`

    const selectedSession = (selectedId == undefined) ? undefined : sessions.filter(x => (x._id == selectedId))[0];
    console.log('UserTrialsPanel: render: props = ', props);
    console.log('--->>> selectedSession = ', selectedSession);

    return (
        <Wrapper>

            <TopSection>

                <div>
                    {`${sessions.length} session${sessions.length > 1 ? 's' : ''}`}
                </div>

                <Button onClick={() => {
                    setAddVisible(true);
                }}>
                    Add session
                </Button>
            </TopSection>

            <SessionsList>

                {sessions.map((a, i) => {
                    let {
                        timestamp_to,
                        timestamp_from,
                        preventicus_customer_id,
                        status,
                        session_code,
                        name,
                        description,
                        patient_code
                    } = a;
                    let toDateString = (timestamp_to == undefined || +timestamp_to == 0) ? '...' : moment(+timestamp_to).format('DD.MM.YYYY HH:mm');
                    return (
                        <SessionItem key={`ss_${a._id}_${i}`}
                                     selected={(a._id == selectedId)}
                                     onClick={() => {
                                         setSelectedId(a._id);
                                     }}>
                            <DatesPlaceholder>
                                {`${moment(+timestamp_from).format('DD.MM.YYYY HH:mm')} - ${toDateString}`}
                            </DatesPlaceholder>
                            <ContentPlaceholder>
                                {`session_code: ${session_code == undefined ? 'N/A' : session_code}`}
                                <br/>
                                {`name: ${name == undefined ? 'N/A' : name}`}
                                <br/>
                                {`patient code: ${patient_code == undefined ? 'N/A' : patient_code}`}
                                <br/>
                                {`description: ${description == undefined ? 'N/A' : description}`}
                                <br/>
                                {`status: ${status}`}
                            </ContentPlaceholder>
                        </SessionItem>
                    )
                })}

            </SessionsList>

            <Sidebar visible={(addVisible)} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <div>
                        <UpdateSessionForm
                            onSave={async d => {
                                console.log('UpdateSessionForm: onSave: d = ', d);
                                await StudyCustomersAPI.createSession(uuid, {
                                    ...d,
                                    from: d.timestamp_from,
                                    to: d.timestamp_to
                                });
                                let arr = await StudyCustomersAPI.getUserSessions(uuid);
                                setSessions(arr);
                                setAddVisible(false);
                            }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar
                width={Math.min(820, window.innerWidth)}
                visible={(selectedSession != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>

                {selectedSession == undefined ? null :
                    <div>
                        <Tabs
                            tabs={[
                                {
                                    label: 'Information',
                                    content: (
                                        <TabItem>
                                            <UpdateSessionForm {...selectedSession}
                                                               onSave={async d => {
                                                                   await StudyCustomersAPI.updateSession(selectedSession._id, {
                                                                       ...d,
                                                                       uuid: uuid,
                                                                       from: d.timestamp_from,
                                                                       to: d.timestamp_to
                                                                   });
                                                                   let arr = await StudyCustomersAPI.getUserSessions(uuid);
                                                                   setSessions(arr);
                                                               }}
                                            />
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Export',
                                    content: (
                                        <TabItem>
                                            <div style={{
                                                textAlign: 'center',
                                                paddingBottom: 5,
                                                marginBottom: 15,
                                                borderBottom: '1px solid whitesmoke'
                                            }}>
                                                {`${moment(+selectedSession.timestamp_from).format('DD.MM.YYYY HH:mm')} - ${(selectedSession.timestamp_to == undefined || selectedSession.timestamp_to == 0) ? '...' : moment(+selectedSession.timestamp_to).format('DD.MM.YYYY HH:mm')}`}
                                            </div>
                                            <div>

                                                {(selectedSession.timestamp_to == undefined || selectedSession.timestamp_to == 0) ? 'Please finish session. After that you will be able to export that.' :
                                                    <React.Fragment>
                                                        <Button onClick={async () => {
                                                            if (exporting == true) {
                                                                return;
                                                            }
                                                            let fromDateString = moment(+selectedSession.timestamp_from).format('YYYY-MM-DD');
                                                            let toDateString = moment(+selectedSession.timestamp_to).format('YYYY-MM-DD');
                                                            if (fromDateString == toDateString) {
                                                                toDateString = moment(+selectedSession.timestamp_from).add(1, 'days').format('YYYY-MM-DD')
                                                            }
                                                            setExporting(true);
                                                            // let pld = await RonasAPI.getRawDataForManyUsersForRange([email], fromDateString, toDateString);
                                                            let pld = await RonasAPI.getRawDataForManyUsersForRange2([email], fromDateString, toDateString);
                                                            setExporting(false);
                                                            console.log('raw data pld = ', pld);
                                                            let sessionName = `${uName}_${moment(+selectedSession.timestamp_from).format('YYYY.MM.DD HH:mm')}-${moment(+selectedSession.timestamp_to).format('YYYY.MM.DD HH:mm')}.zip`;
                                                            // await ExportHelper.exportUsersStats(pld, sessionName);
                                                            await ExportHelper.exportUsersStatsExtended(pld, sessionName, true, +selectedSession.timestamp_from, +selectedSession.timestamp_to);
                                                        }}>
                                                            Export
                                                        </Button>

                                                        <span style={{marginLeft: 5}}>
                                                            <Spin visible={exporting}/>
                                                        </span>
                                                    </React.Fragment>
                                                }

                                            </div>
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Analytics',
                                    content: (
                                        <TabItem>
                                            {isAdmin == false ? null :
                                                <div>
                                                    {(selectedSession.timestamp_from == undefined || selectedSession.timestamp_to == undefined) ?
                                                        <div>session should start and end</div> :
                                                        <div>
                                                            <ZobinImageToool
                                                                uuid={uuid}
                                                                start={selectedSession.timestamp_from}
                                                                end={selectedSession.timestamp_to}/>
                                                        </div>
                                                    }
                                                </div>
                                            }

                                        </TabItem>
                                    )
                                }
                            ]}
                        />
                        {/*Please view this information at CardioMood dashboard*/}
                        {/*{JSON.stringify(selectedSession)}*/}
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;


const ContentPlaceholder = styled.div`

`;

const NamePlaceholder = styled.div`

`;

const DatesPlaceholder = styled.div`
  font-weight: bold;
`;

const SessionsList = styled.div`

`;

const SessionItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  background: $ {
    props
  = >(props . selected = = true ? 'blue': 'white')};
  color: $ {
    props
  = >(props . selected = = true ? 'white': 'black')};
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`;

const Wrapper = styled.div`

`;