import moment from 'moment'

const DrawHelper = {

    getFineGridsForDay(dayTimestamp) {
        let steps = [
            60 * 1000, // 1 min
            2 * 60 * 1000,
            4 * 60 * 1000,
            5 * 60 * 1000,
            10 * 60 * 1000,
            15 * 60 * 1000,
            20 * 60 * 1000,
            30 * 60 * 1000,
            60 * 60 * 1000,
            2 * 60 * 60 * 1000,
            3 * 60 * 60 * 1000,
            4 * 60 * 60 * 1000,
            6 * 60 * 60 * 1000,
        ];
        let from = +moment(dayTimestamp).startOf('day');
        let getArr = (step = steps[0], totalDur = 24 * 60 * 60 * 1000) => {
            let n = Math.floor(totalDur / step);
            let arr = [];
            for (let i = 0; i < n; i++) {
                arr.push(+from + i * step);
            }
            return arr;
        };
        return steps.map(a => getArr(a)).reverse();
    },

    getFineGridsForTimeSpan(from, to) {
        let steps = [
            60 * 1000, // 1 min
            2 * 60 * 1000,
            4 * 60 * 1000,
            5 * 60 * 1000,
            10 * 60 * 1000,
            15 * 60 * 1000,
            20 * 60 * 1000,
            30 * 60 * 1000,
            45 * 60 * 1000,
            60 * 60 * 1000,
            90 * 60 * 1000,
            2 * 60 * 60 * 1000,
            2.5 * 60 * 60 * 1000,
            3 * 60 * 60 * 1000,
            4 * 60 * 60 * 1000,
            6 * 60 * 60 * 1000,
        ];
        let totalDur = +to - +from;
        let getArr = (step = steps[0]) => {
            let n = Math.ceil(1.0 * totalDur / step) + 5;
            let arr = [];
            for (let i = 0; i < n; i++) {
                arr.push(+from + i * step);
            }
            return arr;
        };
        return steps.map(a => getArr(a)).reverse();
    },

    getStepsGridValues(max, number = 4) {
        let steps = [
            0.1, 0.2, 0.4, 0.5, 0.6, 0.8,
            1, 2, 3, 4, 5, 10, 20, 40, 50, 80, 100, 120, 150, 200, 250, 300, 250, 400, 500, 600, 700, 750,
            1000, 1100, 1200, 1250, 1300, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 3250, 3500, 4000, 4500, 5000,
            6000, 7000, 7500, 8000, 9000, 10000, 12000, 13000, 14000, 15000, 17500, 20000
        ];
        let index = 0;
        let arr = steps.sort((a, b) => (+a - +b));
        for (let i in arr) {
            let st = arr[i];
            let x = st * (number - 1); // 0 included
            if (+x > +max) {
                index = i;
                break;
            }
        }
        let step = arr[index];
        let res = [0];
        for (let i = 0; i < number - 1; i++) {
            res.push((+i + 1) * step);
        }
        return res;
    },

    getSleepVertItems(minFromSeconds, maxToSeconds) {
        let step = 7200.0;
        let negStepsNum = (minFromSeconds > 0) ? 0 : Math.ceil(Math.abs(minFromSeconds) / step);
        let posStepsNum = Math.ceil(maxToSeconds / step);
        console.log('getSleepVertItems: negStepsNum, posStepsNum = ', negStepsNum, posStepsNum);

        let n = negStepsNum + posStepsNum;
        let arr = [];
        let from = -(negStepsNum * step);
        let to = posStepsNum * step;
        for (let i = 0; i < n; i++) {
            let t = from + i * step;
            let label = (`${((86400 + t) % 86400) / 3600}`.padStart(2, '0')) + ':00';
            arr.push({
                label: label,
                t: t,
                value: t
            });
        }
        return arr;
    },

    getGridForTimeSpan(from, to, maxN = 10) {
        console.log('getGridForTimeSpan: maxN = ', maxN);
        console.log('getGridForTimeSpan: from, to = ', new Date(from), new Date(to));
        // let grids = this.getFineGridsForTimeSpan(from, to).map(a => a.filter(x => (+x >= +from && +x <= +to)));
        let grids = this.getFineGridsForTimeSpan(from, to);
        console.log('grids = ', grids);
        // console.log('getGridForTimeSpan: grids = ', grids);
        let getIn = (arr, a, b) => arr.reduce((sm, x) => (+x >= +a && +x <= +b ? (+sm + 1) : +sm), 0);
        let scores = grids.map(a => getIn(a, from, to));
        // console.log('scores = ', scores);
        // console.log('maxN = ', maxN);
        let resIndex = grids.length - 1;
        for (let i in scores) {
            let sc = scores[i];
            if (sc <= maxN) {
                resIndex = i;
                // break;
            }
        }
        // console.log('resIndex = ', resIndex);
        let result = grids[resIndex].filter(a => (+a >= +from && +a <= +to));
        console.log('maxN = ', maxN);
        console.log('result = ', result.map(x => new Date(x)));
        console.log('from, to = ', new Date(from), new Date(to));
        return result;
    },

    getMonotoneForAxisHeartRatePoints(oldPoints, minTimestamp, maxTimestamp) {
        let step = 5 * 60 * 1000; // 5 minutes step
        let nFrom = Math.floor(minTimestamp / step) * step;
        let nTo = Math.ceil(maxTimestamp / step) * step;
        let arr = [];
        let map = {};
        for (let p of oldPoints) {
            map[p.date] = p;
        }
        for (let i = nFrom; i <= nTo; i = +i + step) {
            let date = moment(i).format('HH:mm');
            arr.push({
                ...map[date],
                timestamp: i,
                date: date
            });
        }
        return arr;
    },

    getTimestampFromDateString(s) {
        // new Date(Date.parse('2021-02-24T22:47:03.000Z'))
        if (s == undefined) {
            return +new Date();
        }
        if (s.indexOf('T') == -1) {
            s = `${s}T00:00:00`;
        }

        function parseISOLocal(s) {
            var b = s.split(/\D/);
            return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        }
        return +parseISOLocal(s);
    },

    get24HoursStepsArray(slots = []) {
        console.log('get24HoursStepsArray occured! slots = ', slots);
        let arr = Array.from({length: 24}).map((a, i) => ({time: `${i}`.padStart(2, '0') + ':00', value: 0}));
        let getItems = (x) => slots.filter(aa => (aa.date.split(':')[0] == x.split(':')[0]));
        arr = arr.map(x => ({...x, value: getItems(x.time).reduce((sum, q) => (+sum + +q.step_count), 0)}));
        return arr;
    },

    getHeightOfSleepBarByType(type) {
        if (type == 1) { // AWAKE
            return 4;
        }
        if (type == 2) { // LIGHT
            return 2;
        }
        if (type == 3) { // DEEP
            return 1;
        }
        if (type == 4) { // REM
            return 3;
        }
        return 0;
    },

    getColorOfSleepBar(type) {
        let height = this.getHeightOfSleepBarByType(type);
        if (height == 4) {
            return AWAKE_COLOR;
        }
        if (height == 3) {
            return REM_COLOR;
        }
        if (height == 2) {
            return LIGHT_COLOR;
        }
        if (height == 1) {
            return DEEP_COLOR;
        }
        return AWAKE_COLOR;
    },

    getGroupedSleepPoints(points = []) {
        let result = [];
        if (points.length == 0) {
            return [];
        }
        let currPoint = points[0];
        for (let i in points) {
            let p = points[i];
            if (currPoint.type == p.type) {
                currPoint.end_date = p.end_date;
            } else {
                result.push(currPoint);
                currPoint = p;
            }
        }
        result.push(currPoint);
        result = result.map(x => ({
            ...x,
            // from: +new Date(x.start_date),
            // to: +new Date(x.end_date),
            from: this.getTimestampFromDateString(x.start_date),
            to: this.getTimestampFromDateString(x.end_date),
            width: +this.getTimestampFromDateString(x.end_date) - +this.getTimestampFromDateString(x.start_date)
        }));
        if (result.length == 0) {
            return [];
        }
        let totalWidth = +result[result.length - 1].to - +result[0].from;
        let globalFrom = +result[0].from;
        if (totalWidth <= 0) {
            return [];
        }
        result = result.map(x => ({
            ...x,
            width_percent: 100.0 * x.width / totalWidth,
            left_percent: 100.0 * (x.from - globalFrom) / totalWidth
        }))
            .map(x => ({...x, height: this.getHeightOfSleepBarByType(x.type), color: this.getColorOfSleepBar(x.type)}));
        return result;
    }


}

export default DrawHelper;

export const getGoalsMapByArray = goals => {
    let mp = {};
    for (let i in goals) {
        let g = goals[i];
        mp[g.type] = g;
    }
    return mp;
}

export const getColByVal = perc => {
    if (perc == undefined) {
        return RONAS_RED;
    }
    if (perc > 90) {
        return RONAS_DARK_GREEN;
    }
    if (perc > 75) {
        return RONAS_LIGHT_GREEN;
    }
    if (perc > 60) {
        return RONAS_BLUE_MOUSE_OVER;
    }
    return RONAS_RED;
}

export const getProgressColor = progress => {
    if (progress < 30) {
        return RONAS_RED;
    }
    if (progress < 50) {
        return RONAS_BLUE_MOUSE_OVER;
    }
    if (progress < 75) {
        return RONAS_LIGHT_GREEN;
    }
    return RONAS_DARK_GREEN;
}

export const DARK_GREEN = '#31777F';
export const LIGHT_GREEN = '#6D9CA2';
export const LIGHT_BLUE = '#1EB2FC';
export const RED = '#C35A21';

export const RONAS_DARK_GREY = '#191A21';
export const RONAS_DARK_GREEN = '#044000';
export const RONAS_LIGHT_GREEN = '#0C6102';
export const RONAS_RED = '#C90303';
export const RONAS_BLUE_MOUSE_OVER = '#1988E3';

const AWAKE_COLOR = '#FC0903';
const REM_COLOR = '#0FA9FC';
const LIGHT_COLOR = '#3763B5';
const DEEP_COLOR = '#374D89';

